import React from 'react';
import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';

import styles from './PrintChecklist.module.scss';
import Checkbox from '../../components/ui/Checkbox';
import { phoneNumber } from '../../utils/Formatter';
import { getReclaimStatus } from '../../utils/CodeMapper';

const cx = classNames.bind(styles);

const Print = ({ checklist: data, reclaim, close }) => {
  useMountEffect(() => {
    window.print();
    close();
  });

  return (
    <div className={cx('container')}>
      <div className={cx('paperWrapper')}>
        <div className={cx('paper')}>
          <div className={cx('printArea')}>
            <div className={cx('title')}>
              <div className={cx('docTitle')}>수시점검 내역서</div>
              <div className={cx('company')}>두산모빌리티이노베이션</div>
            </div>
            <div className={cx('orderInfo')}>
              <div className={cx('column')}>
                <div className={cx('rows')}>
                  <div className={cx('row')}>
                    <div className={cx('label')}>유형</div>
                    <div className={cx('value')}>: 회수</div>
                  </div>
                  <div className={cx('row')}>
                    <div className={cx('label')}>수량</div>
                    <div className={cx('value')}>: {reclaim.quantity}</div>
                  </div>
                  <div className={cx('row')}>
                    <div className={cx('label')}>상태</div>
                    <div className={cx('value')}>: {getReclaimStatus(reclaim.status)}</div>
                  </div>
                </div>
                <div className={cx('column')}>
                  <div className={cx('rows')}>
                    <div className={cx('row')}>
                      <div className={cx('label')}>요청자</div>
                      <div className={cx('value')}>: {reclaim.customerName}</div>
                    </div>
                    <div className={cx('row')}>
                      <div className={cx('label')}>요청회사</div>
                      <div className={cx('value')}>: {reclaim.customerCompany}</div>
                    </div>
                    <div className={cx('row')}>
                      <div className={cx('label')}>전화번호</div>
                      <div className={cx('value')}>: {phoneNumber(reclaim.customerPhone)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={cx('checklist')}>
              <div className={cx('header')}>
                <div className={cx('term')}>항목</div>
                <div className={cx('answers')}>
                  <div className={cx('answer')}>적합</div>
                  <div className={cx('answer')}>부적합</div>
                </div>
              </div>
              <div className={cx('rows')}>
                {data.checklist.map((item, index) => (
                  <div key={index} className={cx('row')}>
                    <div className={cx('term')}>{`${index + 1}. ${item.question}`}</div>
                    <div className={cx('answers')}>
                      <div className={cx('answer')}>
                        <Checkbox checked={item.agreement} />
                      </div>
                      <div className={cx('answer')}>
                        <Checkbox checked={!item.agreement} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className={cx('author')}>
              <div className={cx('rows')}>
                <div className={cx('row')}>
                  <div className={cx('label')}>점검자</div>
                  <div className={cx('value')}>: {data.createdBy}</div>
                </div>
                <div className={cx('row')}>
                  <div className={cx('label')}>점검일시</div>
                  <div className={cx('value')}>: {data.createdAt}</div>
                </div>
                <div className={cx('row')}>
                  <div className={cx(['label', 'signature'])}>
                    서명 <span>: </span>
                  </div>
                  <div className={cx(['value', 'signature'])}>
                    <img src={data.sign} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Print;
