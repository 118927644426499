import React, { useState, useMemo } from 'react';
import classNames from 'classnames/bind';
import moment from 'moment';
import { ResponsiveBar } from '@nivo/bar';
import Picker from 'rc-calendar/lib/Picker';
import RangeCalendar from 'rc-calendar/lib/RangeCalendar';
import koKR from 'rc-calendar/lib/locale/ko_KR';
import 'rc-calendar/assets/index.css';

import styles from './Chart.module.scss';
import TextInput from '../../../components/ui/TextInput';
import Detail from './Detail';

const cx = classNames.bind(styles);

const Chart = ({ data: orders }) => {
  const [dates, setDates] = useState([moment().startOf('month'), moment()]);
  const [selectedDate, setSelectedDate] = useState();

  const handleClickChartBar = (e) => {
    setSelectedDate(e.data.date);
  };

  // 일자별 주문 수 차트 데이터 정의
  const chartData = useMemo(() => {
    setSelectedDate();

    const nextData = [];
    const currentDate = dates[0].clone();
    while (currentDate.isSameOrBefore(dates[1], 'day')) {
      nextData.push({
        label: currentDate.format('M/D'),
        date: currentDate.format('YYYYMMDD'),
        count: orders?.filter((order) => moment(order.orderDate).isSame(currentDate, 'day')).length ?? 0,
      });

      currentDate.add(1, 'day');
    }

    return nextData;
  }, [orders, dates]);

  // 주문내역 테이블 데이터 정의
  const tableData = useMemo(() => {
    return orders?.filter((order) => moment(order.orderDate).format('YYYYMMDD') === selectedDate);
  }, [orders, selectedDate]);

  const dateFormat = (v) => (v ? v.format('YYYY-MM-DD') : '');
  const calendar = <RangeCalendar dateInputPlaceholder={['시작일자', '종료일자']} locale={koKR} />;

  return (
    <div className={cx('container')}>
      <div className={cx('title')}>일자별 주문 수</div>
      <div className={cx('pickerWrapper')}>
        <Picker value={dates} onChange={setDates} calendar={calendar}>
          {({ value: nextDates }) => (
            <div className={cx('dateInputs')}>
              <TextInput width={96} value={dateFormat(nextDates[0])} readOnly className={cx('dateInput')} />
              <div className={cx('wave')}>~</div>
              <TextInput width={96} value={dateFormat(nextDates[1])} readOnly className={cx('dateInput')} />
            </div>
          )}
        </Picker>
      </div>
      <div className={cx('chartWrapper')}>
        <ResponsiveBar
          data={chartData}
          keys={['count']}
          indexBy="label"
          margin={{ top: 40, right: 40, bottom: 64, left: 64 }}
          padding={0.4}
          colors={{ scheme: 'set1' }}
          labelTextColor="white"
          valueScale={{ type: 'linear' }}
          axisLeft={{
            legend: '주문 수',
            legendPosition: 'middle',
            legendOffset: -40,
            tickSize: 1,
          }}
          axisBottom={{
            legend: '일자',
            legendPosition: 'middle',
            legendOffset: 40,
          }}
          onClick={handleClickChartBar}
        />
      </div>
      {selectedDate && (
        <>
          <div className={cx('divider')} />
          <div className={cx('title')}>{moment(selectedDate).format('M월 D일')} 주문내역</div>
          <Detail data={tableData} />
        </>
      )}
    </div>
  );
};

export default Chart;
