import React, { useState } from 'react';
import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { IoAddOutline, IoRemoveCircle } from 'react-icons/io5';
import { toast } from 'react-toastify';

import styles from '../../styles/form.module.scss';
import API from '../../helpers/API';
import Button from '../../components/ui/Button';
import Table from '../../components/ui/Table';
import Controlbar from '../../components/ui/Controlbar';
import ContentWrapper from '../../components/ui/ContentWrapper';
import FormSectionTitle from '../../components/ui/FormSectionTitle';
import FormRow from '../../components/ui/FormRow';
import SearchTanks from '../../components/modals/SearchTanks';
import CodePanel from '../../components/ui/CodePanel';
import { phoneNumber } from '../../utils/Formatter';
import { getOrderStatus, getTankUseStatus, getTankChargeStatus } from '../../utils/CodeMapper';
import Breadcrumb from '../../components/ui/Breadcrumb';

const cx = classNames.bind(styles);

const Page = () => {
  const { rid } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState();
  const [orderTanks, setOrderTanks] = useState();
  const [visiblePanel, setVisiblePanel] = useState(false);
  const [codeForPanel, setCodeForPanel] = useState(null);

  useMountEffect(() => {
    // API: 주문 상세 조회
    API.get(`/orders/${rid}`).then(({ success, data }) => {
      if (success) {
        setOrder(data);
        setOrderTanks(data.orderTanks);
      }
    });
  });

  // 용기 선택
  const selectTank = (tank) => {
    if (tank) {
      // API: 주문-용기 할당
      API.post(`/orders/${rid}/tanks/${tank.rid}`).then(({ success, data }) => {
        if (success) {
          setOrderTanks([...orderTanks, { tank, createdAt: moment().format('YYYY-MM-DD HH:mm:ss') }]);
        } else {
          toast.error('할당 가능한 용기가 아닙니다.');
        }
      });
    }
    setVisiblePanel(false);
  };

  // 용기 해제
  const deselectTank = (item) => {
    // API: 주문-용기 삭제
    API.delete(`/orders/${rid}/tanks/${item.tank.rid}`).then(({ success }) => {
      if (success) {
        setOrderTanks(orderTanks.filter((orderTank) => orderTank.tank.rid !== item.tank.rid));
      }
    });
  };

  const moveToBack = () => {
    navigate(-1);
  };

  const customerColumns = [
    {
      key: 'customerName',
      name: '성명',
      exp: (item) => item.customerName,
      style: { flex: 1 },
    },
    {
      key: 'customerCompany',
      name: '회사명',
      exp: (item) => item.customerCompany,
      style: { flex: 1 },
    },
    {
      key: 'customerPhone',
      name: '전화번호',
      exp: (item) => phoneNumber(item.customerPhone),
      style: { flex: 1 },
    },
  ];

  const deliveryColumns = [
    {
      key: 'deliveryName',
      name: '성명',
      exp: (item) => item.deliveryName,
      style: { flex: 1 },
    },
    {
      key: 'deliveryCompany',
      name: '회사명',
      exp: (item) => item.deliveryCompany,
      style: { flex: 1 },
    },
    {
      key: 'deliveryPhone',
      name: '전화번호',
      exp: (item) => phoneNumber(item.deliveryPhone),
      style: { flex: 1 },
    },
  ];

  const tankColumns = [
    {
      key: 'rid',
      name: 'RID',
      exp: (item) => <span className={cx('monospace')}>{item.tank.rid.substring(0, 8)}</span>,
      width: 104,
      align: 'center',
      hidden: true,
      onPress: (item) => {
        const element = document.createElement('textarea');
        element.value = item.tank.rid;
        document.body.appendChild(element);

        element.select();
        document.execCommand('copy');
        document.body.removeChild(element);
      },
    },
    {
      key: 'qrcode',
      name: 'QR코드',
      exp: (item) => item.tank.qrcode,
      width: 120,
      align: 'center',
      onPress: (item) => setCodeForPanel(item.tank.qrcode),
    },
    {
      key: 'serialNo',
      name: '용기 일련번호',
      exp: (item) => item.tank.serialNo,
      style: { flex: 1 },
      onPress: (item) => navigate(`/tanks/${item.tank.rid}`),
    },
    {
      key: 'useStatus',
      name: '가용상태',
      exp: (item) => getTankUseStatus(item.tank.useStatus),
      width: 80,
      align: 'center',
      onPress: (item) => navigate(`/tanks/${item.tank.rid}`),
    },
    {
      key: 'chargeStatus',
      name: '충전상태',
      exp: (item) => getTankChargeStatus(item.tank.chargeStatus),
      width: 80,
      align: 'center',
      onPress: (item) => navigate(`/tanks/${item.tank.rid}`),
    },
    {
      key: 'chargeCount',
      name: '충전횟수',
      exp: (item) => item.tank.chargeCount,
      width: 80,
      align: 'center',
      onPress: (item) => navigate(`/tanks/${item.tank.rid}`),
    },
    {
      key: 'createdAt',
      name: '할당일시',
      exp: (item) => moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss'),
      width: 160,
      align: 'center',
      onPress: (item) => navigate(`/tanks/${item.tank.rid}`),
    },
    {
      key: 'createdBy',
      name: '할당자',
      exp: (item) => (item.createdBy ? item.createdBy.name : '-'),
      width: 80,
      align: 'center',
      onPress: (item) => navigate(`/tanks/${item.tank.rid}`),
    },
    {
      key: 'delete',
      name: '해제',
      exp: (item) => <IoRemoveCircle size={20} color="red" />,
      width: 80,
      align: 'center',
      onPress: deselectTank,
    },
  ];

  return (
    <div className={cx('container')}>
      <Controlbar>
        <div>
          <Breadcrumb />
        </div>
      </Controlbar>
      <ContentWrapper>
        {order && (
          <div>
            <FormSectionTitle title="주문정보" />
            <FormRow title="주문번호">
              <div className={cx('value')}>{order.originNo}</div>
            </FormRow>
            <FormRow title="주문일자">
              <div className={cx('value')}>{moment(order.orderDate, 'YYYYMMDD').format('YYYY-MM-DD')}</div>
            </FormRow>
            <FormRow title="상태">
              <div className={cx('value')}>{getOrderStatus(order.status)}</div>
            </FormRow>
            <FormRow title="수량">
              <div className={cx('value')}>{order.quantity}</div>
            </FormRow>
            <FormRow title="주문자">
              <div className={cx('tableWrapperCell')}>
                <div className={cx(['tableWrapper', 'single'])}>
                  <Table absoluteFill={false} columns={customerColumns} data={[order]} />
                </div>
              </div>
            </FormRow>
            <FormSectionTitle title="배송정보" />
            <FormRow title="수신자">
              <div className={cx('tableWrapperCell')}>
                <div className={cx(['tableWrapper', 'single'])}>
                  <Table absoluteFill={false} columns={deliveryColumns} data={[order]} />
                </div>
              </div>
            </FormRow>
            <FormRow title="주소">
              <div className={cx('value')}>
                {order.deliveryAddress} {order.deliveryAddressDetail}
              </div>
            </FormRow>
            <FormRow title="용기 내역">
              <div className={cx('tableWrapperCell')}>
                <div className={cx('tableControl')}>
                  <div className={cx(['button', 'icon'])} onClick={() => setVisiblePanel(true)}>
                    <IoAddOutline size={20} />
                  </div>
                  {visiblePanel && (
                    <div className={cx('panelWrapper')}>
                      <SearchTanks
                        callback={selectTank}
                        filterOptions={{ useStatus: 'READY', chargeStatus: 'CHARGED' }}
                      />
                    </div>
                  )}
                </div>
                <div className={cx(['tableWrapper', 'multi'])}>
                  <Table columns={tankColumns} data={orderTanks} />
                  <CodePanel code={codeForPanel} close={() => setCodeForPanel(null)} />
                </div>
              </div>
            </FormRow>
          </div>
        )}
      </ContentWrapper>
      <Controlbar>
        <Button title="뒤로" onClick={moveToBack} />
      </Controlbar>
    </div>
  );
};

export default Page;
