import React from 'react';
import classNames from 'classnames/bind';
import { IoAlertCircleOutline } from 'react-icons/io5';

import styles from './EmptyContainer.module.scss';

const cx = classNames.bind(styles);

const EmptyContainer = ({ icon }) => {
  return (
    <div className={cx('container')}>
      {icon && <IoAlertCircleOutline size={32} color="gray" className={cx('icon')} />}
      <div className={cx('message')}>조회 결과가 없습니다.</div>
    </div>
  );
};

export default EmptyContainer;
