import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import styles from '../../styles/form.module.scss';
import API from '../../helpers/API';
import TextInput from '../../components/ui/TextInput';
import Textarea from '../../components/ui/Textarea';
import Button from '../../components/ui/Button';
import Controlbar from '../../components/ui/Controlbar';
import ContentWrapper from '../../components/ui/ContentWrapper';
import FormSectionTitle from '../../components/ui/FormSectionTitle';
import FormRow from '../../components/ui/FormRow';
import FormRowInfo from '../../components/ui/FormRowInfo';
import Breadcrumb from '../../components/ui/Breadcrumb';
import { isEmail } from '../../utils/FormatChecker';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const [post, setPost] = useState({
    title: '',
    body: '',
    emails: '',
  });

  const handleChange = (e) => {
    setPost({
      ...post,
      [e.target.name]: e.target.value,
    });
  };

  const moveToBack = () => {
    navigate(-1);
  };

  const validation = () => {
    if (post.title.trim() === '') {
      toast.error('제목이 입력되지 않았습니다.');
      return false;
    }
    if (post.body.trim() === '') {
      toast.error('내용이 입력되지 않았습니다.');
      return false;
    }

    // 잘못된 이메일 주소 색출
    const invalidEmail = post.emails
      .trim()
      .split('\n')
      .map((email) => email.trim())
      .find((email) => isEmail(email) === false);

    if (invalidEmail) {
      toast.error('이메일 주소가 바르지 않습니다.');
      return false;
    }

    return true;
  };

  const doSave = () => {
    if (!validation()) {
      return;
    }

    const data = {
      title: post.title,
      body: post.body,
      emails: post.emails
        .trim()
        .split('\n')
        .map((email) => email.trim()),
    };

    API.post('/posts', data).then(({ success }) => {
      if (success) {
        navigate('/posts');
      } else {
        toast.error('저장을 실패했습니다.');
      }
    });
  };

  return (
    <div className={cx('container')}>
      <Controlbar>
        <div>
          <Breadcrumb />
        </div>
      </Controlbar>
      <ContentWrapper>
        <FormSectionTitle title="기본정보" />
        <FormRow title="제목" required>
          <TextInput name="title" width={240} value={post.title} placeholder="제목" onChange={handleChange} />
        </FormRow>
        <FormRow title="내용" required>
          <Textarea name="body" value={post.body} placeholder="내용" onChange={handleChange} />
        </FormRow>
        <FormRow title="수신 이메일">
          <Textarea name="emails" value={post.emails} placeholder="수신 이메일" onChange={handleChange} />
          <FormRowInfo>각 이메일 주소 줄바꿈 구분</FormRowInfo>
        </FormRow>
      </ContentWrapper>
      <Controlbar>
        <Button title="뒤로" onClick={moveToBack} />
        <Button title="저장" onClick={doSave} />
      </Controlbar>
    </div>
  );
};

export default Page;
