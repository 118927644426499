import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import styles from './Button.module.scss';

const cx = classNames.bind(styles);

const Button = ({ variant = 'filled', title, onClick, style = {} }) => {
  return (
    <div className={cx(['container', variant])} onClick={onClick} style={style}>
      {title}
    </div>
  );
};

Button.propTypes = {
  variant: PropTypes.oneOf(['filled', 'bordered']),
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.object,
};

export default Button;
