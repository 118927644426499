import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import classNames from 'classnames/bind';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import styles from './App.module.scss';
import Sidebar from './components/ui/Sidebar';
import Loading from './components/ui/Loading';
import ProtectedRoute from './helpers/ProtectedRoute';
import UnprotectedRoute from './helpers/UnprotectedRoute';
import Home from './pages/home';
import Login from './pages/login';
import AutoLoign from './pages/autologin';

// 현황
import MonitorTanks from './pages/dashboard/tanks';
import MonitorOrders from './pages/dashboard/orders';
// 주문 관리
import OrderList from './pages/orders';
import OrderNew from './pages/orders/new';
import OrderDetail from './pages/orders/detail';
import OrderTanks from './pages/orders/tanks';
import OrderShipping from './pages/orders/shipping';
import OrderChecklist from './pages/orders/checklist';
import ReclaimList from './pages/reclaims';
import ReclaimNew from './pages/reclaims/new';
import ReclaimDetail from './pages/reclaims/detail';
import ReclaimTanks from './pages/reclaims/tanks';
import ReclaimShipping from './pages/reclaims/shipping';
import ReclaimChecklist from './pages/reclaims/checklist';
// 운영 관리
import PostList from './pages/posts';
import PostNew from './pages/posts/new';
import PostDetail from './pages/posts/detail';
// 마스터 관리
import UserList from './pages/users';
import UserNew from './pages/users/new';
import UserDetail from './pages/users/detail';
import LocationList from './pages/locations';
import LocationNew from './pages/locations/new';
import LocationDetail from './pages/locations/detail';
import TankList from './pages/tanks';
import TankNew from './pages/tanks/new';
import TankDetail from './pages/tanks/detail';

const cx = classNames.bind(styles);

const App = () => {
  return (
    <BrowserRouter>
      <div className={cx('container')}>
        <Loading />
        <ToastContainer
          closeOnClick
          newestOnTop
          pauseOnFocusLoss={false}
          pauseOnHover={false}
          style={{ width: '480px' }}
        />
        <Sidebar />
        <Routes>
          <Route
            path="/login"
            element={
              <UnprotectedRoute>
                <Login />
              </UnprotectedRoute>
            }
          />
          <Route
            path="/autologin"
            element={
              <UnprotectedRoute>
                <AutoLoign />
              </UnprotectedRoute>
            }
          />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/tanks"
            element={
              <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN']}>
                <MonitorTanks />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/orders"
            element={
              <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN']}>
                <MonitorOrders />
              </ProtectedRoute>
            }
          />
          <Route
            path="/orders"
            element={
              <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN', 'MSGAS']}>
                <OrderList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/orders/new"
            element={
              <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN', 'MSGAS']}>
                <OrderNew />
              </ProtectedRoute>
            }
          />
          <Route
            path="/orders/:rid"
            element={
              <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN', 'MSGAS']}>
                <OrderDetail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/orders/:rid/tanks"
            element={
              <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN', 'MSGAS']}>
                <OrderTanks />
              </ProtectedRoute>
            }
          />
          <Route
            path="/orders/:rid/shipping"
            element={
              <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN', 'MSGAS']}>
                <OrderShipping />
              </ProtectedRoute>
            }
          />
          <Route
            path="/orders/:rid/shipping/checklist"
            element={
              <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN', 'MSGAS']}>
                <OrderChecklist />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reclaims"
            element={
              <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN', 'MSGAS']}>
                <ReclaimList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reclaims/new"
            element={
              <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN', 'MSGAS']}>
                <ReclaimNew />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reclaims/:rid"
            element={
              <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN', 'MSGAS']}>
                <ReclaimDetail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reclaims/:rid/tanks"
            element={
              <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN', 'MSGAS']}>
                <ReclaimTanks />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reclaims/:rid/shipping"
            element={
              <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN', 'MSGAS']}>
                <ReclaimShipping />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reclaims/:rid/shipping/checklist"
            element={
              <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN', 'MSGAS']}>
                <ReclaimChecklist />
              </ProtectedRoute>
            }
          />
          <Route
            path="/posts"
            element={
              <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN']}>
                <PostList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/posts/new"
            element={
              <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN']}>
                <PostNew />
              </ProtectedRoute>
            }
          />
          <Route
            path="/posts/:rid"
            element={
              <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN']}>
                <PostDetail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/users"
            element={
              <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN']}>
                <UserList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/users/new"
            element={
              <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN']}>
                <UserNew />
              </ProtectedRoute>
            }
          />
          <Route
            path="/users/:rid"
            element={
              <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN']}>
                <UserDetail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/locations"
            element={
              <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN']}>
                <LocationList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/locations/new"
            element={
              <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN']}>
                <LocationNew />
              </ProtectedRoute>
            }
          />
          <Route
            path="/locations/:rid"
            element={
              <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN']}>
                <LocationDetail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/tanks"
            element={
              <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN']}>
                <TankList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/tanks/new"
            element={
              <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN']}>
                <TankNew />
              </ProtectedRoute>
            }
          />
          <Route
            path="/tanks/:rid"
            element={
              <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN']}>
                <TankDetail />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;
