import React, { useState } from 'react';
import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';

import styles from '../../styles/form.module.scss';
import API from '../../helpers/API';
import TextInput from '../../components/ui/TextInput';
import Textarea from '../../components/ui/Textarea';
import Button from '../../components/ui/Button';
import Controlbar from '../../components/ui/Controlbar';
import ContentWrapper from '../../components/ui/ContentWrapper';
import FormSectionTitle from '../../components/ui/FormSectionTitle';
import FormRow from '../../components/ui/FormRow';
import Select from '../../components/ui/Select';
import Breadcrumb from '../../components/ui/Breadcrumb';
import { getAddress } from '../../utils/AddressFinder';

const cx = classNames.bind(styles);

const Page = () => {
  const { rid } = useParams();
  const navigate = useNavigate();
  const [reclaim, setReclaim] = useState();
  const [locations, setLocations] = useState();
  const [customerNames, setCustomerNames] = useState();

  useMountEffect(() => {
    // API: 거점 내역 조회
    API.get('/locations').then(({ success, data }) => {
      if (success) {
        setLocations(data.locations);
      }
    });

    // API: 주문 내역 조회
    API.get('/orders').then(({ success, data }) => {
      if (success) {
        // 모든 고객명 배열화
        const allCustomerNames = data.orders.map((order) => order.customerName.trim());
        // 고객명 중복제거 및 오름차순 정렬
        const sortedCustomerNames = [...new Set(allCustomerNames)].sort();
        // 고객명 정의
        setCustomerNames(sortedCustomerNames);
      }
    });

    // API: 회수 상세 조회
    API.get(`/reclaims/${rid}`).then(({ success, data }) => {
      if (success) {
        setReclaim({
          ...data,
          reclaimLocationRid: data.reclaimLocation.rid,
        });
      }
    });
  });

  const handleChange = (e) => {
    setReclaim({
      ...reclaim,
      [e.target.name]: e.target.value,
    });
  };

  const findAddress = () => {
    getAddress().then((data) => setReclaim({ ...reclaim, customerAddress: data.address }));
  };

  const moveToBack = () => {
    navigate(-1);
  };

  const moveToOrderTanks = () => {
    navigate(`/reclaims/${rid}/tanks`);
  };

  const moveToOrderShipping = () => {
    if (reclaim.reclaimTanks.length === 0) {
      toast.error('할당된 용기가 없습니다.');
      return;
    }
    navigate(`/reclaims/${rid}/shipping`);
  };

  const validation = () => {
    if (reclaim.quantity === '') {
      toast.error('수량이 입력되지 않았습니다.');
      return false;
    }
    if (reclaim.customerName.trim() === '') {
      toast.error('고객명이 입력되지 않았습니다.');
      return false;
    }
    if (reclaim.customerCompany.trim() === '') {
      toast.error('회사명이 입력되지 않았습니다.');
      return false;
    }
    if (reclaim.customerPhone.trim() === '') {
      toast.error('전화번호가 입력되지 않았습니다.');
      return false;
    }
    if (reclaim.customerAddress.trim() === '') {
      toast.error('주소가 입력되지 않았습니다.');
      return false;
    }

    return true;
  };

  const doSave = () => {
    if (!validation()) {
      return;
    }

    const data = {
      quantity: Number(reclaim.quantity),
      customerName: reclaim.customerName,
      customerCompany: reclaim.customerCompany,
      customerPhone: reclaim.customerPhone,
      customerAddress: reclaim.customerAddress,
      customerAddressDetail: reclaim.customerAddressDetail,
      reclaimLocationRid: reclaim.reclaimLocationRid,
      memo: reclaim.memo,
    };

    // API: 회수 수정
    API.patch(`/reclaims/${rid}`, data).then(({ success }) => {
      if (success) {
        navigate('/reclaims');
      } else {
        toast.error('저장을 실패했습니다.');
      }
    });
  };

  return (
    <div className={cx('container')}>
      <Controlbar>
        <div>
          <Breadcrumb />
        </div>
      </Controlbar>
      <ContentWrapper>
        {reclaim && (
          <div>
            <FormSectionTitle title="회수정보" />
            <FormRow title="수량" required>
              <TextInput
                name="quantity"
                width={80}
                value={reclaim.quantity}
                placeholder="수량"
                onChange={handleChange}
              />
            </FormRow>
            <FormRow title="고객명" required>
              <Select width={240} name="customerName" onChange={handleChange} value={reclaim.customerName}>
                {customerNames?.map((customerName, index) => (
                  <option key={index} value={customerName}>
                    {customerName}
                  </option>
                ))}
              </Select>
            </FormRow>
            <FormRow title="회사명" required>
              <TextInput
                name="customerCompany"
                width={320}
                value={reclaim.customerCompany}
                placeholder="회사명"
                onChange={handleChange}
              />
            </FormRow>
            <FormRow title="전화번호" required>
              <TextInput
                name="customerPhone"
                width={240}
                value={reclaim.customerPhone}
                placeholder="전화번호"
                onChange={handleChange}
              />
            </FormRow>
            <FormRow title="주소" required>
              <TextInput
                name="customerAddress"
                width={400}
                value={reclaim.customerAddress}
                placeholder="주소"
                readOnly
                disabled
              />
              <TextInput
                name="customerAddressDetail"
                width={320}
                value={reclaim.customerAddressDetail}
                placeholder="상세주소 (선택)"
                onChange={handleChange}
                style={{ marginLeft: 8 }}
              />
              <Button variant="bordered" title="주소 찾기" onClick={findAddress} style={{ marginLeft: 8 }} />
            </FormRow>
            <FormRow title="최종 거점" required>
              {locations && (
                <Select
                  width={240}
                  name="reclaimLocationRid"
                  value={reclaim.reclaimLocationRid}
                  onChange={handleChange}>
                  {locations.map((location) => (
                    <option key={location.rid} value={location.rid}>
                      {location.name}
                    </option>
                  ))}
                </Select>
              )}
            </FormRow>
            <FormSectionTitle title="부가정보" />
            <FormRow title="메모">
              <Textarea name="memo" value={reclaim.memo} placeholder="메모" height={120} onChange={handleChange} />
            </FormRow>
            <FormRow title="등록일시">
              <div className={cx('value')}>{moment(reclaim.createdAt).format('YYYY-MM-DD HH:mm:ss')}</div>
            </FormRow>
            <FormRow title="등록자">
              <div className={cx('value')}>{reclaim.createdBy.name}</div>
            </FormRow>
            <FormRow title="수정일시">
              <div className={cx('value')}>{moment(reclaim.updatedAt).format('YYYY-MM-DD HH:mm:ss')}</div>
            </FormRow>
            <FormRow title="수정자">
              <div className={cx('value')}>{reclaim.updatedBy.name}</div>
            </FormRow>
          </div>
        )}
      </ContentWrapper>
      <Controlbar>
        <Button title="뒤로" onClick={moveToBack} />
        <div>
          <Button title="용기 관리" onClick={moveToOrderTanks} />
          <Button title="배송 관리" onClick={moveToOrderShipping} />
          <Button title="저장" onClick={doSave} />
        </div>
      </Controlbar>
    </div>
  );
};

export default Page;
