import React, { useState, useMemo } from 'react';
import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import { IoCloseOutline } from 'react-icons/io5';

import styles from './SearchTanks.module.scss';
import API from '../../helpers/API';
import Table from '../ui/Table';
import { getTankUseStatus, getTankChargeStatus } from '../../utils/CodeMapper';

const cx = classNames.bind(styles);

const SearchTanks = ({ callback, filterOptions }) => {
  const [tanks, setTanks] = useState();

  useMountEffect(() => {
    API.get('/tanks').then(({ success, data }) => {
      setTanks(success ? data.tanks : []);
    });
  });

  const close = () => {
    callback(null);
  };

  const columns = [
    {
      key: 'serialNo',
      name: '용기 일련번호',
      exp: (item) => item.serialNo,
      style: { flex: 1 },
      onPress: (item) => callback(item),
    },
    {
      key: 'useStatus',
      name: '가용상태',
      exp: (item) => getTankUseStatus(item.useStatus),
      width: 80,
      align: 'center',
      onPress: (item) => callback(item),
    },
    {
      key: 'chargeStatus',
      name: '충전상태',
      exp: (item) => getTankChargeStatus(item.chargeStatus),
      width: 80,
      align: 'center',
      onPress: (item) => callback(item),
    },
    {
      key: 'chargeCount',
      name: '충전횟수',
      exp: (item) => item.chargeCount,
      width: 80,
      align: 'center',
      onPress: (item) => callback(item),
    },
    {
      key: 'location',
      name: '현재 위치',
      exp: (item) => item.location?.name ?? '-',
      width: 184,
      align: 'center',
      onPress: (item) => callback(item),
    },
  ];

  const filteredTanks = useMemo(() => {
    if (tanks === undefined) return;

    return tanks
      .filter((tank) => tank.useStatus === filterOptions.useStatus)
      .filter((tank) => {
        if (filterOptions.chargeStatus) {
          return tank.chargeStatus === filterOptions.chargeStatus;
        }
        return true;
      })
      .filter((tank) => {
        if (filterOptions.locationName) {
          return tank.location.name === filterOptions.locationName;
        }
        return true;
      });
  }, [tanks]);

  return (
    <div className={cx('container')}>
      <div className={cx('header')}>
        <div className={cx('title')}>용기 내역</div>
        <div className={cx('close')} onClick={close}>
          <IoCloseOutline size={24} color="white" />
        </div>
      </div>
      <div className={cx('body')}>
        <Table columns={columns} data={filteredTanks} />
      </div>
    </div>
  );
};

export default SearchTanks;
