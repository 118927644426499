const TankSizeSelect = ({ value, onChange, className }) => {
  return (
    <select value={value} onChange={onChange} className={className}>
      <option value="">전체</option>
      <option value={7}>7L</option>
      <option value={9}>9L</option>
      <option value={10.8}>10.8L</option>
      <option value={12}>12L</option>
    </select>
  );
};

export default TankSizeSelect;
