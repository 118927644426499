import React, { useState } from 'react';
import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import moment from 'moment';

import styles from '../../../styles/dashboard.module.scss';
import API from '../../../helpers/API';
import Button from '../../../components/ui/Button';
import Controlbar from '../../../components/ui/Controlbar';
import ContentWrapper from '../../../components/ui/ContentWrapper';
import CountBoxes from './CountBoxes';
import Chart from './Chart';

const cx = classNames.bind(styles);

const Page = () => {
  const [orders, setOrders] = useState();

  useMountEffect(() => {
    queryOrder();
  });

  const queryOrder = () => {
    API.get('/orders').then(({ success, data }) => {
      if (success) {
        setOrders(data.orders);
      }
    });
  };

  const search = () => {
    queryOrder();
  };

  return (
    <div className={cx('container')}>
      <Controlbar>
        <div className={cx('time')}>{moment().format('YYYY년 M월 D일 HH:mm 기준')}</div>
        <div>
          <Button title="조회" onClick={search} />
        </div>
      </Controlbar>
      <ContentWrapper>
        <CountBoxes data={orders} />
        <Chart data={orders} />
      </ContentWrapper>
    </div>
  );
};

export default Page;
