import React from 'react';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';

import styles from './Loading.module.scss';

const cx = classNames.bind(styles);

const Loading = () => {
  const { isLoading } = useSelector((state) => state.loading);

  if (!isLoading) return;

  return (
    <div className={cx('container')}>
      <div className={cx('modal')}>
        <img width={64} src={require('../../assets/images/spinner.gif')} alt="" />
      </div>
    </div>
  );
};

export default Loading;
