import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import styles from '../../styles/form.module.scss';
import API from '../../helpers/API';
import TextInput from '../../components/ui/TextInput';
import Textarea from '../../components/ui/Textarea';
import Button from '../../components/ui/Button';
import Controlbar from '../../components/ui/Controlbar';
import ContentWrapper from '../../components/ui/ContentWrapper';
import FormSectionTitle from '../../components/ui/FormSectionTitle';
import FormRow from '../../components/ui/FormRow';
import Select from '../../components/ui/Select';
import Breadcrumb from '../../components/ui/Breadcrumb';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    id: '',
    password: '',
    rePassword: '',
    name: '',
    role: 'USER',
    email: '',
    phone: '',
    memo: '',
  });

  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const moveToBack = () => {
    navigate(-1);
  };

  const validation = () => {
    if (user.id.trim() === '') {
      toast.error('아이디가 입력되지 않았습니다.');
      return false;
    }
    if (user.password.trim() === '') {
      toast.error('비밀번호가 입력되지 않았습니다.');
      return false;
    }
    if (user.password !== user.rePassword) {
      toast.error('비밀번호가 일치하지 않습니다.');
      return false;
    }
    if (user.name.trim() === '') {
      toast.error('성명이 입력되지 않았습니다.');
      return false;
    }

    return true;
  };

  const doSave = () => {
    if (!validation()) {
      return;
    }

    const data = {
      id: user.id,
      password: user.password,
      name: user.name,
      role: user.role,
      email: user.email,
      phone: user.phone,
      memo: user.memo,
    };

    API.post('/users', data).then(({ success }) => {
      if (success) {
        navigate('/users');
      } else {
        toast.error('저장을 실패했습니다.');
      }
    });
  };

  return (
    <div className={cx('container')}>
      <Controlbar>
        <div>
          <Breadcrumb />
        </div>
      </Controlbar>
      <ContentWrapper>
        <FormSectionTitle title="기본정보" />
        <FormRow title="아이디" required>
          <TextInput name="id" width={240} value={user.id} placeholder="아이디" onChange={handleChange} />
        </FormRow>
        <FormRow title="비밀번호" required>
          <TextInput
            type="password"
            name="password"
            width={240}
            value={user.password}
            placeholder="비밀번호"
            onChange={handleChange}
          />
        </FormRow>
        <FormRow title="비밀번호 확인" required>
          <TextInput
            type="password"
            name="rePassword"
            width={240}
            value={user.rePassword}
            placeholder="비밀번호 확인"
            onChange={handleChange}
          />
        </FormRow>
        <FormRow title="성명" required>
          <TextInput name="name" width={120} value={user.name} placeholder="성명" onChange={handleChange} />
        </FormRow>
        <FormRow title="역할" required>
          <Select width={120} name="role" value={user.role} onChange={handleChange}>
            <option value="SYSTEM">시스템관리자</option>
            <option value="ADMIN">통합관리자</option>
            <option value="MANAGER">충전관리자</option>
            <option value="CHARGER">충전원</option>
            <option value="DELIVERER">배송원</option>
            <option value="SALES">영업사원</option>
          </Select>
        </FormRow>
        <FormRow title="이메일">
          <TextInput name="email" width={320} value={user.email} placeholder="이메일" onChange={handleChange} />
        </FormRow>
        <FormRow title="전화번호">
          <TextInput name="phone" width={240} value={user.phone} placeholder="전화번호" onChange={handleChange} />
        </FormRow>
        <FormSectionTitle title="부가정보" />
        <FormRow title="메모">
          <Textarea name="memo" value={user.memo} placeholder="메모" height={120} onChange={handleChange} />
        </FormRow>
      </ContentWrapper>
      <Controlbar>
        <Button title="뒤로" onClick={moveToBack} />
        <Button title="저장" onClick={doSave} />
      </Controlbar>
    </div>
  );
};

export default Page;
