import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useNavigate, useLocation } from 'react-router-dom';
import { IoChevronDownOutline, IoChevronUpOutline } from 'react-icons/io5';

import styles from './Sidebar.module.scss';
import API, { removeAuthToken } from '../../helpers/API';

const cx = classNames.bind(styles);

const MENUS = [
  {
    title: '현황',
    submenus: [
      { title: '용기 현황', uri: '/dashboard/tanks', roles: ['SYSTEM', 'ADMIN'] },
      { title: '주문 현황', uri: '/dashboard/orders', roles: ['SYSTEM', 'ADMIN'] },
    ],
  },
  {
    title: '접수 관리',
    submenus: [
      { title: '주문 관리', uri: '/orders', roles: ['SYSTEM', 'ADMIN', 'MSGAS'] },
      { title: '회수 관리', uri: '/reclaims', roles: ['SYSTEM', 'ADMIN', 'MSGAS'] },
    ],
  },
  {
    title: '운영 관리',
    submenus: [{ title: '공지사항 관리', uri: '/posts', roles: ['SYSTEM', 'ADMIN'] }],
  },
  {
    title: '마스터 관리',
    submenus: [
      { title: '사용자 관리', uri: '/users', roles: ['SYSTEM', 'ADMIN'] },
      { title: '거점 관리', uri: '/locations', roles: ['SYSTEM', 'ADMIN'] },
      { title: '용기 관리', uri: '/tanks', roles: ['SYSTEM', 'ADMIN'] },
    ],
  },
];

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [hidden, setHidden] = useState(true);
  const [menus, setMenus] = useState();

  useEffect(() => {
    setHidden(localStorage.getItem('@h2logis/token') === null);
    buildMenus(localStorage.getItem('@h2logis/role'));
  }, [location]);

  // 메뉴 구성
  const buildMenus = (role) => {
    const filteredMenus = MENUS.filter((menu) => {
      return menu.submenus.find((submenu) => submenu.roles.includes(role));
    });
    const nextMenus = filteredMenus.map((menu) => {
      return {
        ...menu,
        submenus: menu.submenus.filter((submenu) => submenu.roles.includes(role)),
        expand: true,
      };
    });
    setMenus(nextMenus);
  };

  const toggleMenuGroup = (index) => {
    menus[index].expand = !menus[index].expand;
    setMenus([...menus]);
  };

  // 로그아웃
  const doLogout = () => {
    if (window.confirm('로그아웃 하시겠습니까?')) {
      API.post('/logout');

      removeAuthToken();
      navigate('/login');
    }
  };

  if (hidden) {
    return null;
  }

  return (
    <div className={cx('container')}>
      <div className={cx('wrapper')}>
        <div className={cx('logoWrapper')}>
          <div className={cx('logo')} onClick={() => navigate('/')} />
        </div>
        <div className={cx('menusWrapper')}>
          {menus.map((menu, index) => (
            <div key={index} className={cx('menuGroup')}>
              <div className={cx('groupHeader')} onClick={() => toggleMenuGroup(index)}>
                <div className={cx('title')}>{menu.title}</div>
                {menu.expand ? (
                  <IoChevronUpOutline size={16} color="white" />
                ) : (
                  <IoChevronDownOutline size={16} color="white" />
                )}
              </div>
              {menu.expand && (
                <div className={cx('submenusWrapper')}>
                  {menu.submenus.map((submenu, index) => (
                    <div
                      key={index}
                      className={cx(['submenu', { active: location.pathname.startsWith(submenu.uri) }])}
                      onClick={() => navigate(submenu.uri)}>
                      {submenu.title}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
        <div className={cx('bottomMenu')} onClick={doLogout}>
          로그아웃
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
