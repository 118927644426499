import React, { useMemo } from 'react';
import classNames from 'classnames/bind';
import commaNumber from 'comma-number';
import moment from 'moment';

import styles from './CountBoxes.module.scss';

const cx = classNames.bind(styles);

const CountBoxes = ({ data: orders }) => {
  const startDateInWeek = moment().startOf('week');
  const endDateInWeek = moment().endOf('week');

  // 총 주문 수
  const countAll = useMemo(() => {
    return orders?.length ?? 0;
  }, [orders]);

  // 이번 달 주문 수
  const countMonth = useMemo(() => {
    const filteredOrders = orders?.filter(
      (order) => moment(order.orderDate).format('YYYYMM') === moment().format('YYYYMM')
    );
    return filteredOrders?.length ?? 0;
  }, [orders]);

  // 이번 주 주문 수
  const countWeek = useMemo(() => {
    const filteredOrders = orders?.filter(
      (order) =>
        moment(order.orderDate).isSameOrAfter(startDateInWeek) && moment(order.orderDate).isSameOrBefore(endDateInWeek)
    );
    return filteredOrders?.length ?? 0;
  }, [orders]);

  // 오늘 주문 수
  const countToday = useMemo(() => {
    const filteredOrders = orders?.filter((order) => moment(order.orderDate).isSame(moment(), 'day'));
    return filteredOrders?.length ?? 0;
  }, [orders]);

  return (
    <div className={cx('container')}>
      <div className={cx('box')}>
        <div className={cx('title')}>총 주문 수</div>
        <div className={cx('value')}>{commaNumber(countAll)}</div>
      </div>
      <div className={cx('box')}>
        <div className={cx('title')}>이번 달 주문 수</div>
        <div className={cx('value')}>{commaNumber(countMonth)}</div>
        <div className={cx('meta')}>{moment().format('YYYY년 M월')}</div>
      </div>
      <div className={cx('box')}>
        <div className={cx('title')}>이번 주 주문 수</div>
        <div className={cx('value')}>{commaNumber(countWeek)}</div>
        <div className={cx('meta')}>
          {startDateInWeek.format('M월 D일')}
          {endDateInWeek.format(' ~ M월 D일')}
        </div>
      </div>
      <div className={cx('box')}>
        <div className={cx('title')}>오늘 주문 수</div>
        <div className={cx('value')}>{commaNumber(countToday)}</div>
        <div className={cx('meta')}>{moment().format('M월 D일')}</div>
      </div>
    </div>
  );
};

export default CountBoxes;
