import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import moment from 'moment';

import styles from '../../../styles/card.module.scss';
import Table from '../../../components/ui/Table';

const cx = classNames.bind(styles);

const OPTIONS = {
  TANK: '용기',
  VELVE: '밸브',
};

const ByInspectionDate = ({ data: tanks }) => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(OPTIONS.TANK);
  const [acceptableDays, setAcceptableDays] = useState(30);

  const handleChangeSelectOption = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleChangeAcceptableDays = (e) => {
    const enteredValue = Number(e.target.value);

    if (!isNaN(enteredValue)) {
      setAcceptableDays(enteredValue);
    }
  };

  const columns = [
    {
      key: 'serialNo',
      name: '일련번호',
      exp: (item) => (selectedOption === OPTIONS.TANK ? item.serialNo : item.velveSerialNo),
      style: { flex: 1 },
      onPress: (item) => navigate(`/tanks/${item.rid}`),
    },
    {
      key: 'inspectionDate',
      name: '점검일자',
      exp: (item) => item.inspectionDate.format('YYYY-MM-DD'),
      width: 100,
      align: 'center',
      onPress: (item) => navigate(`/tanks/${item.rid}`),
    },
    {
      key: 'remainingDays',
      name: '잔여일수',
      width: 80,
      align: 'center',
      exp: (item) => item.remainingDays,
    },
  ];

  const data = useMemo(() => {
    if (tanks === undefined) return;

    return (
      tanks
        // 임시, 검사, 폐기 상태 용기 제외
        .filter((tank) => !['DRAFT', 'INSPECTING', 'RETIRED'].includes(tank.useStatus))
        .map((tank) => {
          const sourceDate = selectedOption === OPTIONS.TANK ? tank.manufacturedDate : tank.velveExpireDate;
          const inspectionDate = moment(sourceDate, 'YYYYMMDD');

          // 용기 5년, 밸브 1년
          const incrementYears = selectedOption === OPTIONS.TANK ? 5 : 1;

          while (inspectionDate.isBefore(moment())) {
            inspectionDate.add(incrementYears, 'years');
          }

          const remainingDays = moment(inspectionDate).diff(moment(), 'day') + 1;

          return {
            ...tank,
            inspectionDate,
            remainingDays,
          };
        })
        // 점검일자까지 잔여일수가 입력일 이하인 데이터
        .filter((tank) => tank.remainingDays <= acceptableDays)
    );
  }, [tanks, selectedOption, acceptableDays]);

  return (
    <div className={cx('container')}>
      <div className={cx('header')}>
        <div className={cx('title')}>점검기한 만료</div>
        <div className={cx('options')}>
          <select value={selectedOption} onChange={handleChangeSelectOption} className={cx('select')}>
            <option value={OPTIONS.TANK}>{OPTIONS.TANK}</option>
            <option value={OPTIONS.VELVE}>{OPTIONS.VELVE}</option>
          </select>
          <div className={cx('fieldWrapper')}>
            <input value={acceptableDays} onChange={handleChangeAcceptableDays} maxLength={3} className={cx('field')} />
            <span className={cx('post')}>일 이내</span>
          </div>
        </div>
      </div>
      <div className={cx('body')}>
        <Table data={data} columns={columns} />
      </div>
    </div>
  );
};

export default ByInspectionDate;
