import React, { useState } from 'react';
import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import moment from 'moment';

import styles from '../../../styles/dashboard.module.scss';
import API from '../../../helpers/API';
import Button from '../../../components/ui/Button';
import Controlbar from '../../../components/ui/Controlbar';
import ContentWrapper from '../../../components/ui/ContentWrapper';
import ByUseStatus from './ByUseStatus';
import ByChargeStatus from './ByChargeStatus';
import ByLocation from './ByLocation';
import ByCustomer from './ByCustomer';
import ByDayCount from './ByDayCount';
import ByExpireDate from './ByExpireDate';
import ByInspectionDate from './ByInspectionDate';

const cx = classNames.bind(styles);

const Page = () => {
  const [tanks, setTanks] = useState();

  useMountEffect(() => {
    queryTanks();
  });

  const queryTanks = () => {
    API.get('/tanks').then(({ success, data }) => {
      if (success) {
        setTanks(data.tanks);
      }
    });
  };

  const search = () => {
    queryTanks();
  };

  return (
    <div className={cx('container')}>
      <Controlbar>
        <div className={cx('time')}>{moment().format('YYYY년 M월 D일 HH:mm 기준')}</div>
        <div>
          <Button title="조회" onClick={search} />
        </div>
      </Controlbar>
      <ContentWrapper>
        <div className={cx('wrapper')}>
          <ByLocation data={tanks} />
          <ByCustomer data={tanks} />
          <ByUseStatus data={tanks} />
          <ByChargeStatus data={tanks} />
          <ByDayCount data={tanks} />
          <ByExpireDate data={tanks} />
          <ByInspectionDate data={tanks} />
        </div>
      </ContentWrapper>
    </div>
  );
};

export default Page;
