import React from 'react';
import classNames from 'classnames/bind';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoChevronForwardOutline } from 'react-icons/io5';

import style from './Breadcrumb.module.scss';
import { isUUID } from '../../utils/FormatChecker';

const cx = classNames.bind(style);

const ROUTES = {
  orders: '주문',
  reclaims: '회수',
  posts: '공지사항',
  users: '사용자',
  locations: '거점',
  tanks: '용기',
  new: '신규',
  checklist: '체크리스트',
  shipping: '배송',
};

const Breadcrumb = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const slugs = pathname.split('/').slice(1);
  const terms = slugs.map((slug) => (isUUID(slug) ? '상세' : ROUTES[slug]));

  const moveToPage = (index) => {
    const destinationPath = '/' + slugs.slice(0, index + 1).join('/');
    navigate(destinationPath);
  };

  return (
    <div className={cx('container')}>
      {terms.map((term, index) => (
        <div key={index} className={cx('term')}>
          <div onClick={() => moveToPage(index)} className={cx('name')}>
            {term}
          </div>
          {index < terms.length - 1 && <IoChevronForwardOutline color="white" size={14} className={cx('chevron')} />}
        </div>
      ))}
    </div>
  );
};

export default Breadcrumb;
