import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ allowedRoles, children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('@h2logis/token');
    const role = localStorage.getItem('@h2logis/role');

    if (token === null || role === null) {
      navigate('/login');
    } else if (allowedRoles !== undefined && !allowedRoles.includes(role)) {
      navigate('/');
    }
  }, [location.pathname]);

  return children;
};

export default ProtectedRoute;
