import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import styles from '../../styles/form.module.scss';
import API from '../../helpers/API';
import TextInput from '../../components/ui/TextInput';
import Textarea from '../../components/ui/Textarea';
import Button from '../../components/ui/Button';
import Controlbar from '../../components/ui/Controlbar';
import ContentWrapper from '../../components/ui/ContentWrapper';
import FormSectionTitle from '../../components/ui/FormSectionTitle';
import FormRow from '../../components/ui/FormRow';
import Breadcrumb from '../../components/ui/Breadcrumb';
import { getAddress } from '../../utils/AddressFinder';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const [location, setLocation] = useState({
    name: '',
    address: '',
    addressDetail: '',
    phone: '',
    latitude: '',
    longitude: '',
    managerName: '',
    managerPhone: '',
    memo: '',
  });

  const handleChange = (e) => {
    setLocation({
      ...location,
      [e.target.name]: e.target.value,
    });
  };

  const findAddress = () => {
    getAddress().then((data) => setLocation({ ...location, address: data.address }));
  };

  const moveToBack = () => {
    navigate(-1);
  };

  const validation = () => {
    if (location.name.trim() === '') {
      toast.error('거점명이 입력되지 않았습니다.');
      return false;
    }
    if (location.address.trim() === '') {
      toast.error('주소가 입력되지 않았습니다.');
      return false;
    }
    if (location.phone.trim() === '') {
      toast.error('전화번호가 입력되지 않았습니다.');
      return false;
    }
    if (location.latitude.trim() === '' || location.longitude.trim() === '') {
      toast.error('위치 정보가 입력되지 않았습니다.');
      return false;
    }
    if (location.managerName.trim() === '') {
      toast.error('담당자명이 입력되지 않았습니다.');
      return false;
    }
    if (location.managerPhone.trim() === '') {
      toast.error('담당자 전화번호가 입력되지 않았습니다.');
      return false;
    }

    return true;
  };

  const doSave = () => {
    if (!validation()) {
      return;
    }

    const data = {
      name: location.name,
      address: location.address,
      addressDetail: location.addressDetail,
      phone: location.phone,
      latitude: location.latitude,
      longitude: location.longitude,
      managerName: location.managerName,
      managerPhone: location.managerPhone,
      memo: location.memo,
    };

    API.post('/locations', data).then(({ success }) => {
      if (success) {
        navigate('/locations');
      } else {
        toast.error('저장을 실패했습니다.');
      }
    });
  };

  return (
    <div className={cx('container')}>
      <Controlbar>
        <div>
          <Breadcrumb />
        </div>
      </Controlbar>
      <ContentWrapper>
        <FormSectionTitle title="기본정보" />
        <FormRow title="거점명" required>
          <TextInput name="name" width={240} value={location.name} placeholder="거점명" onChange={handleChange} />
        </FormRow>
        <FormRow title="주소" required>
          <TextInput name="address" width={400} value={location.address} placeholder="주소" readOnly disabled />
          <TextInput
            name="addressDetail"
            width={320}
            value={location.addressDetail}
            placeholder="상세주소 (선택)"
            onChange={handleChange}
            style={{ marginLeft: 8 }}
          />
          <Button variant="bordered" title="주소 찾기" onClick={findAddress} style={{ marginLeft: 8 }} />
        </FormRow>
        <FormRow title="전화번호" required>
          <TextInput name="phone" width={240} value={location.phone} placeholder="전화번호" onChange={handleChange} />
        </FormRow>
        <FormRow title="위치" required>
          <TextInput
            name="latitude"
            width={160}
            value={location.latitude}
            placeholder="위도"
            onChange={handleChange}
            className={cx('w240')}
          />
          <TextInput
            name="longitude"
            width={160}
            value={location.longitude}
            placeholder="경도"
            onChange={handleChange}
            className={cx('field')}
            style={{ marginLeft: 8 }}
          />
        </FormRow>
        <FormRow title="담당자명" required>
          <TextInput
            name="managerName"
            width={120}
            value={location.managerName}
            placeholder="담당자명"
            onChange={handleChange}
          />
        </FormRow>
        <FormRow title="담당자 전화번호" required>
          <TextInput
            name="managerPhone"
            width={240}
            value={location.managerPhone}
            placeholder="담당자 전화번호"
            onChange={handleChange}
          />
        </FormRow>
        <FormSectionTitle title="부가정보" />
        <FormRow title="메모">
          <Textarea name="memo" value={location.memo} placeholder="메모" height={120} onChange={handleChange} />
        </FormRow>
      </ContentWrapper>
      <Controlbar>
        <Button title="뒤로" onClick={moveToBack} />
        <Button title="저장" onClick={doSave} />
      </Controlbar>
    </div>
  );
};

export default Page;
