import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import commaNumber from 'comma-number';
import moment from 'moment';

import styles from '../../styles/list.module.scss';
import API from '../../helpers/API';
import Button from '../../components/ui/Button';
import Table from '../../components/ui/Table';
import Controlbar from '../../components/ui/Controlbar';
import ContentWrapper from '../../components/ui/ContentWrapper';
import { getOrderStatus } from '../../utils/CodeMapper';
import { downloadExcelFile } from '../../utils/FileDownloader';
import { showLoading } from '../../store/loadingSlice';

const cx = classNames.bind(styles);

const Page = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [orders, setOrders] = useState();
  const [filters, setFilters] = useState({ orderStatus: searchParams.get('orderStatus') ?? '' });

  useEffect(() => {
    search();
  }, [location]);

  const handleChangeFilter = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const searchWithParams = () => {
    const nextSearchParams = {};
    Object.keys(filters).forEach((key) => {
      if (filters[key] !== '') {
        nextSearchParams[key] = filters[key];
      }
    });

    setSearchParams(nextSearchParams);
  };

  const search = () => {
    dispatch(showLoading(true));

    // API: 주문 내역 조회
    API.get('/orders')
      .then(({ success, data }) => {
        if (success) {
          setOrders(data.orders);
        }
      })
      .finally(() => dispatch(showLoading(false)));
  };

  const download = () => {
    const records = orders.map((order) => {
      return {
        A: order.originNo,
        B: moment(order.orderDate).format('YYYY-MM-DD'),
        C: getOrderStatus(order.status),
        D: order.quantity,
        E: order.customerName,
        F: order.deliveryName,
        G: `${order.deliveryAddress} ${order.deliveryAddressDetail}`,
        H: moment(order.createdAt).format('YYYY-MM-DD HH:mm:ss'),
        I: order.createdBy.name,
        J: moment(order.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
        K: order.updatedBy.name,
      };
    });

    downloadExcelFile(
      [
        {
          A: '주문번호',
          B: '주문일자',
          C: '상태',
          D: '수량',
          E: '주문자명',
          F: '수신자명',
          G: '주소',
          H: '등록일시',
          I: '등록자',
          J: '수정일시',
          K: '수정자',
        },
      ],
      records,
      `주문내역-${moment().format('YYYYMMDDHHmmss')}`
    );
  };

  const moveToForm = () => {
    navigate('/orders/new');
  };

  const columns = [
    {
      key: 'rid',
      name: 'RID',
      exp: (item) => <span className={cx('monospace')}>{item.rid.substring(0, 8)}</span>,
      width: 104,
      align: 'center',
      hidden: true,
      onPress: (item) => {
        const element = document.createElement('textarea');
        element.value = item.rid;
        document.body.appendChild(element);

        element.select();
        document.execCommand('copy');
        document.body.removeChild(element);
      },
    },
    {
      key: 'originNo',
      name: '주문번호',
      exp: (item) => item.originNo,
      width: 120,
      align: 'center',
      onPress: (item) => navigate(`/orders/${item.rid}`),
    },
    {
      key: 'orderDate',
      name: '주문일자',
      exp: (item) => moment(item.orderDate, 'YYYYMMDD').format('YYYY-MM-DD'),
      width: 120,
      align: 'center',
      onPress: (item) => navigate(`/orders/${item.rid}`),
    },
    {
      key: 'status',
      name: '상태',
      exp: (item) => getOrderStatus(item.status),
      width: 80,
      align: 'center',
      onPress: (item) => navigate(`/orders/${item.rid}`),
    },
    {
      key: 'quantity',
      name: '수량',
      exp: (item) => item.quantity,
      width: 80,
      align: 'center',
      onPress: (item) => navigate(`/orders/${item.rid}`),
    },
    {
      key: 'customerName',
      name: '주문자명',
      exp: (item) => <div className={cx('ellipsis')}>{item.customerName}</div>,
      width: 120,
      onPress: (item) => navigate(`/orders/${item.rid}`),
    },
    {
      key: 'deliveryName',
      name: '수신자명',
      exp: (item) => <div className={cx('ellipsis')}>{item.deliveryName}</div>,
      width: 120,
      onPress: (item) => navigate(`/orders/${item.rid}`),
    },
    {
      key: 'address',
      name: '주소',
      exp: (item) => (
        <div className={cx('ellipsis')}>
          {item.deliveryAddress} {item.deliveryAddressDetail}
        </div>
      ),
      style: { flex: 1 },
      onPress: (item) => navigate(`/orders/${item.rid}`),
    },
    {
      key: 'createdAt',
      name: '등록일시',
      exp: (item) => moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss'),
      width: 160,
      align: 'center',
      onPress: (item) => navigate(`/orders/${item.rid}`),
    },
    {
      key: 'createdBy',
      name: '등록자',
      exp: (item) => item.createdBy.name,
      width: 80,
      align: 'center',
      onPress: (item) => navigate(`/orders/${item.rid}`),
    },
    {
      key: 'updatedAt',
      name: '수정일시',
      exp: (item) => moment(item.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
      width: 160,
      align: 'center',
      onPress: (item) => navigate(`/orders/${item.rid}`),
    },
    {
      key: 'updatedBy',
      name: '수정자',
      exp: (item) => item.updatedBy.name,
      width: 80,
      align: 'center',
      onPress: (item) => navigate(`/orders/${item.rid}`),
    },
  ];

  const filteredOrders = useMemo(() => {
    if (orders === undefined) return;

    return orders.filter((order) => {
      const orderStatus = searchParams.get('orderStatus') ?? '';

      if (orderStatus !== '') {
        return order.status === orderStatus;
      }

      return true;
    });
  }, [orders, location]);

  return (
    <div className={cx('container')}>
      <Controlbar>
        <div className={cx('total')}>{filteredOrders && `총 ${commaNumber(filteredOrders.length)}`}건</div>
        <div>
          <select
            name="orderStatus"
            value={filters?.orderStatus ?? ''}
            onChange={handleChangeFilter}
            className={cx('select')}>
            <option value="">상태</option>
            <option value="RECEIVED">주문접수</option>
            <option value="CHARGING">준비중</option>
            <option value="SHIPPING">배송중</option>
            <option value="DELIVERED">배송완료</option>
            <option value="CANCELED">주문취소</option>
          </select>
          <Button title="엑셀 다운로드" onClick={download} />
          <Button title="조회" onClick={searchWithParams} />
        </div>
      </Controlbar>
      <ContentWrapper>
        <Table columns={columns} data={filteredOrders} />
      </ContentWrapper>
      <Controlbar>
        <div />
        <div>
          <Button title="신규" onClick={moveToForm} />
        </div>
      </Controlbar>
    </div>
  );
};

export default Page;
