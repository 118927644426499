import React, { useState } from 'react';
import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import commaNumber from 'comma-number';
import moment from 'moment';

import styles from '../../styles/list.module.scss';
import API from '../../helpers/API';
import Button from '../../components/ui/Button';
import Table from '../../components/ui/Table';
import Controlbar from '../../components/ui/Controlbar';
import ContentWrapper from '../../components/ui/ContentWrapper';
import { showLoading } from '../../store/loadingSlice';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [total, setTotal] = useState(0);
  const [posts, setPosts] = useState();

  useMountEffect(() => {
    search();
  });

  const search = () => {
    dispatch(showLoading(true));

    // API: 공지 내역 조회
    API.get('/posts')
      .then(({ success, data }) => {
        if (success) {
          setTotal(data.totalCount);
          setPosts(data.posts);
        }
      })
      .finally(() => dispatch(showLoading(false)));
  };

  const moveToForm = () => {
    navigate('/posts/new');
  };

  const columns = [
    {
      key: 'rid',
      name: 'RID',
      exp: (item) => <span className={cx('monospace')}>{item.rid.substring(0, 8)}</span>,
      width: 104,
      align: 'center',
      hidden: true,
      onPress: (item) => {
        const element = document.createElement('textarea');
        element.value = item.rid;
        document.body.appendChild(element);

        element.select();
        document.execCommand('copy');
        document.body.removeChild(element);
      },
    },
    {
      key: 'title',
      name: '제목',
      exp: (item) => <div className={cx('ellipsis')}>{item.title}</div>,
      style: { flex: 1 },
      onPress: (item) => navigate(`/posts/${item.rid}`),
    },
    {
      key: 'createdAt',
      name: '등록일시',
      exp: (item) => moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss'),
      width: 160,
      align: 'center',
      onPress: (item) => navigate(`/posts/${item.rid}`),
    },
    {
      key: 'createdBy',
      name: '등록자',
      exp: (item) => item.createdBy.name,
      width: 80,
      align: 'center',
      onPress: (item) => navigate(`/posts/${item.rid}`),
    },
    {
      key: 'updatedAt',
      name: '수정일시',
      exp: (item) => moment(item.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
      width: 160,
      align: 'center',
      onPress: (item) => navigate(`/posts/${item.rid}`),
    },
    {
      key: 'updatedBy',
      name: '수정자',
      exp: (item) => item.updatedBy.name,
      width: 80,
      align: 'center',
      onPress: (item) => navigate(`/posts/${item.rid}`),
    },
  ];

  return (
    <div className={cx('container')}>
      <Controlbar>
        <div className={cx('total')}>총 {commaNumber(total)}건</div>
        <div>
          <Button title="조회" onClick={search} />
        </div>
      </Controlbar>
      <ContentWrapper>
        <Table columns={columns} data={posts} />
      </ContentWrapper>
      <Controlbar>
        <div />
        <div>
          <Button title="신규" onClick={moveToForm} />
        </div>
      </Controlbar>
    </div>
  );
};

export default Page;
