import React, { useEffect, useState, useRef, useMemo } from 'react';
import classNames from 'classnames/bind';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import commaNumber from 'comma-number';
import moment from 'moment';
import { toast } from 'react-toastify';

import styles from '../../styles/list.module.scss';
import API from '../../helpers/API';
import Button from '../../components/ui/Button';
import Table from '../../components/ui/Table';
import Controlbar from '../../components/ui/Controlbar';
import ContentWrapper from '../../components/ui/ContentWrapper';
import CodePanel from '../../components/ui/CodePanel';
import { downloadExcelFile } from '../../utils/FileDownloader';
import { getTankUseStatus, getTankChargeStatus } from '../../utils/CodeMapper';
import { showLoading } from '../../store/loadingSlice';
import Print from './Print';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [tanks, setTanks] = useState();
  const [codeForPanel, setCodeForPanel] = useState(null);
  const [showPaper, setShowPaper] = useState(false);
  const [filters, setFilters] = useState({
    useStatus: searchParams.get('useStatus') ?? '',
    chargeStatus: searchParams.get('chargeStatus') ?? '',
    velveManufacturer: searchParams.get('velveManufacturer') ?? '',
    customer: searchParams.get('customer') ?? '',
  });

  const tableRef = useRef();
  const selectedTanks = useRef();

  useEffect(() => {
    search();
  }, [location]);

  const handleChangeFilter = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const searchWithParams = () => {
    const nextSearchParams = {};
    Object.keys(filters).forEach((key) => {
      // 필터 값 존재 시
      if (filters[key] !== '') {
        // 검색 파라미터 정의
        nextSearchParams[key] = filters[key];
      }
    });

    setSearchParams(nextSearchParams);
  };

  const search = async () => {
    dispatch(showLoading(true));

    // API: 용기 내역 조회
    API.get('/tanks')
      .then(({ success, data }) => {
        if (success) {
          setTanks(data.tanks);
        }
      })
      .finally(() => dispatch(showLoading(false)));
  };

  const download = () => {
    const records = filteredTanks.map((tank) => {
      return {
        A: tank.qrcode,
        B: getTankUseStatus(tank.useStatus),
        C: getTankChargeStatus(tank.chargeStatus),
        D: tank.location?.name ?? '',
        E: tank.type,
        F: tank.size ? `${tank.size}L` : '',
        G: tank.region,
        H: tank.serialNo,
        I: tank.manufacturer,
        J: tank.velveSerialNo,
        K: tank.velveManufacturer,
        L: tank.velveVersion,
        M: 'MS가스',
        N: tank.chargeCount,
        O: tank.deliveryInfo ? tank.deliveryInfo.deliveryCompany : '',
        P: tank.deliveryInfo ? moment(tank.deliveryInfo.deliveredAt).format('YYYY-MM-DD') : '',
        Q: tank.deliveryInfo ? moment().diff(moment(tank.deliveryInfo.deliveredAt), 'day') + 1 : '',
        R: tank.manufacturedDate,
        S: tank.expireDate ? moment(tank.expireDate, 'YYYYMMDD').format('YYYY-MM-DD') : '',
        T: tank.velveExpireDate ? moment(tank.velveExpireDate, 'YYYYMMDD').format('YYYY-MM-DD') : '',
        // U: tank.retiredAt ? moment(tank.retiredAt).format('YYYYMMDDHHmmss') : '',
        // V: tank.retiredBy ? tank.retiredBy.name : '',
        W: tank.memo,
      };
    });

    downloadExcelFile(
      [
        {
          A: 'QR코드',
          B: '가용상태',
          C: '충전상태',
          D: '현재 위치',
          E: '유형',
          F: '용량',
          G: '지역',
          H: '용기 일련번호',
          I: '용기 제조사',
          J: '밸브 일련번호',
          K: '밸브 제조사',
          L: '밸브 버전',
          M: '충전소',
          N: '충전횟수',
          O: '주문고객',
          P: '배송일자',
          Q: '보유일수',
          R: '제조일자',
          S: '용기 충전기한',
          T: '밸브 충전기한',
          // U: '폐기일시',
          // V: '폐기자',
          W: '메모',
        },
      ],
      records,
      `용기내역-${moment().format('YYYYMMDDHHmmss')}`
    );
  };

  // 일괄 삭제
  const doRemove = () => {
    selectedTanks.current = tableRef.current.getCheckedData();
    if (selectedTanks.current.length === 0) {
      toast.error('선택된 용기가 없습니다.');
      return;
    }

    if (!window.confirm('용기를 삭제하시겠습니까?')) {
      return;
    }

    const data = { rids: selectedTanks.current.map((tank) => tank.rid) };

    API.delete('/tanks', { data }).then(({ success }) => {
      if (success) {
        window.location.reload();
      } else {
        toast.error('삭제를 실패했습니다.');
      }
    });
  };

  // 일괄 폐기
  const doRetire = () => {
    selectedTanks.current = tableRef.current.getCheckedData();
    if (selectedTanks.current.length === 0) {
      toast.error('선택된 용기가 없습니다.');
      return;
    }

    if (!window.confirm('용기를 폐기하시겠습니까?')) {
      return;
    }

    API.patch(`/tanks/retire`, { rids: selectedTanks.current.map((tank) => tank.rid) }).then(({ success }) => {
      if (success) {
        window.location.reload();
      } else {
        toast.error('폐기를 실패했습니다.');
      }
    });
  };

  const preparePrint = () => {
    selectedTanks.current = tableRef.current.getCheckedData();
    if (selectedTanks.current.length === 0) {
      toast.error('선택된 용기가 없습니다.');
      return;
    }

    setShowPaper(true);
  };

  const moveToForm = () => {
    navigate('/tanks/new');
  };

  const columns = [
    {
      key: 'rid',
      name: 'RID',
      exp: (item) => <span className={cx('monospace')}>{item.rid.substring(0, 8)}</span>,
      width: 104,
      align: 'center',
      hidden: true,
      onPress: (item) => {
        const element = document.createElement('textarea');
        element.value = item.rid;
        document.body.appendChild(element);

        element.select();
        document.execCommand('copy');
        document.body.removeChild(element);
      },
    },
    {
      key: 'qrcode',
      name: 'QR코드',
      exp: (item) => item.qrcode,
      sort: (item) => item.qrcode,
      width: 120,
      align: 'center',
      onPress: (item) => setCodeForPanel(item.qrcode),
    },
    {
      key: 'useStatus',
      name: '가용상태',
      exp: (item) => getTankUseStatus(item.useStatus),
      sort: (item) => getTankUseStatus(item.useStatus),
      width: 80,
      align: 'center',
      onPress: (item) => navigate(`/tanks/${item.rid}`),
    },
    {
      key: 'chargeStatus',
      name: '충전상태',
      exp: (item) => getTankChargeStatus(item.chargeStatus),
      sort: (item) => getTankChargeStatus(item.chargeStatus),
      width: 80,
      align: 'center',
      onPress: (item) => navigate(`/tanks/${item.rid}`),
    },
    {
      key: 'location',
      name: '현재 위치',
      exp: (item) => item.location?.name ?? '-',
      sort: (item) => item.location?.name,
      width: 184,
      align: 'center',
      onPress: (item) => navigate(`/tanks/${item.rid}`),
    },
    {
      key: 'type',
      name: '유형',
      exp: (item) => item.type ?? '-',
      sort: (item) => item.type,
      width: 80,
      align: 'center',
      onPress: (item) => navigate(`/tanks/${item.rid}`),
    },
    {
      key: 'size',
      name: '용량',
      exp: (item) => (item.size ? `${item.size}L` : '-'),
      sort: (item) => item.size,
      width: 80,
      align: 'center',
      onPress: (item) => navigate(`/tanks/${item.rid}`),
    },
    {
      key: 'region',
      name: '지역',
      exp: (item) => item.region ?? '-',
      sort: (item) => item.region,
      width: 80,
      align: 'center',
      onPress: (item) => navigate(`/tanks/${item.rid}`),
    },
    {
      key: 'serialNo',
      name: '용기 일련번호',
      exp: (item) => (item.serialNo ? <div className={cx('ellipsis')}>{item.serialNo}</div> : '-'),
      sort: (item) => item.serialNo,
      style: { flex: 1 },
      onPress: (item) => navigate(`/tanks/${item.rid}`),
    },
    {
      key: 'manufacturer',
      name: '용기 제조사',
      exp: (item) => item.manufacturer ?? '-',
      sort: (item) => item.manufacturer,
      width: 96,
      align: 'center',
      onPress: (item) => navigate(`/tanks/${item.rid}`),
    },
    {
      key: 'velveSerialNo',
      name: '밸브 일련번호',
      exp: (item) => (item.velveSerialNo ? <div className={cx('ellipsis')}>{item.velveSerialNo}</div> : '-'),
      sort: (item) => item.velveSerialNo,
      style: { flex: 1 },
      onPress: (item) => navigate(`/tanks/${item.rid}`),
    },
    {
      key: 'velveManufacturer',
      name: '밸브 제조사',
      exp: (item) => item.velveManufacturer ?? '-',
      sort: (item) => item.velveManufacturer,
      width: 96,
      align: 'center',
      onPress: (item) => navigate(`/tanks/${item.rid}`),
    },
    {
      key: 'velveVersion',
      name: '밸브 버전',
      exp: (item) => item.velveVersion ?? '-',
      sort: (item) => item.velveVersion,
      width: 80,
      align: 'center',
      onPress: (item) => navigate(`/tanks/${item.rid}`),
    },
    {
      key: 'chargeStation',
      name: '충전소',
      exp: (item) => 'MS가스',
      sort: (item) => 'MS가스',
      width: 80,
      align: 'center',
      onPress: (item) => navigate(`/tanks/${item.rid}`),
    },
    {
      key: 'chargeCount',
      name: '충전횟수',
      exp: (item) => commaNumber(item.chargeCount),
      sort: (item) => item.chargeCount,
      width: 80,
      align: 'center',
      onPress: (item) => navigate(`/tanks/${item.rid}`),
    },
    {
      key: 'customer',
      name: '주문고객',
      exp: (item) =>
        item.deliveryInfo ? <div className={cx('ellipsis')}>{item.deliveryInfo.deliveryCompany}</div> : '-',
      sort: (item) => item.deliveryInfo?.deliveryCompany,
      width: 160,
      onPress: (item) => navigate(`/tanks/${item.rid}`),
    },
    {
      key: 'deliveredAt',
      name: '배송일자',
      exp: (item) => (item.deliveryInfo ? moment(item.deliveryInfo.deliveredAt).format('YYYY-MM-DD') : '-'),
      sort: (item) => item.deliveryInfo?.deliveredAt,
      width: 120,
      align: 'center',
      onPress: (item) => navigate(`/tanks/${item.rid}`),
    },
    {
      key: 'dayCount',
      name: '보유일수',
      exp: (item) => (item.deliveryInfo ? moment().diff(moment(item.deliveryInfo.deliveredAt), 'day') + 1 : '-'),
      sort: (item) => item.deliveryInfo?.deliveredAt,
      width: 80,
      align: 'center',
      onPress: (item) => navigate(`/tanks/${item.rid}`),
    },
    {
      key: 'manufacturedDate',
      name: '제조일자',
      exp: (item) => (item.manufacturedDate ? moment(item.manufacturedDate, 'YYYYMMDD').format('YYYY-MM-DD') : '-'),
      sort: (item) => item.manufacturedDate,
      width: 120,
      align: 'center',
      onPress: (item) => navigate(`/tanks/${item.rid}`),
    },
    {
      key: 'expireDate',
      name: '용기 충전기한',
      exp: (item) => (item.expireDate ? moment(item.expireDate, 'YYYYMMDD').format('YYYY-MM-DD') : '-'),
      sort: (item) => item.expireDate,
      width: 120,
      align: 'center',
      onPress: (item) => navigate(`/tanks/${item.rid}`),
    },
    {
      key: 'velveExpireDate',
      name: '밸브 충전기한',
      exp: (item) => (item.velveExpireDate ? moment(item.velveExpireDate, 'YYYYMMDD').format('YYYY-MM-DD') : '-'),
      sort: (item) => item.velveExpireDate,
      width: 120,
      align: 'center',
      onPress: (item) => navigate(`/tanks/${item.rid}`),
    },
    {
      key: 'checkDate',
      name: '용기 점검일자',
      exp: (item) => {
        if (item.manufacturedDate === null) {
          return '-';
        }

        const checkDate = moment(item.manufacturedDate, 'YYYYMMDD');
        while (checkDate.isBefore(moment())) {
          checkDate.add(5, 'years');
        }

        if (checkDate.format('YYYYMM') === moment().format('YYYYMM')) {
          return <span style={{ color: 'red' }}>{checkDate.format('YYYY-MM-DD')}</span>;
        } else {
          return checkDate.format('YYYY-MM-DD');
        }
      },
      sort: (item) => item.manufacturedDate,
      width: 120,
      align: 'center',
      onPress: (item) => navigate(`/tanks/${item.rid}`),
    },
    {
      key: 'velveCheckDate',
      name: '밸브 점검일자',
      exp: (item) => {
        if (item.velveExpireDate === null) {
          return '-';
        }

        const velveCheckDate = moment(item.velveExpireDate, 'YYYYMMDD');
        while (velveCheckDate.isBefore(moment())) {
          velveCheckDate.add(1, 'years');
        }

        if (velveCheckDate.format('YYYYMM') === moment().format('YYYYMM')) {
          return <span style={{ color: 'red' }}>{velveCheckDate.format('YYYY-MM-DD')}</span>;
        } else {
          return velveCheckDate.format('YYYY-MM-DD');
        }
      },
      sort: (item) => item.velveExpireDate,
      width: 120,
      align: 'center',
      onPress: (item) => navigate(`/tanks/${item.rid}`),
    },
    {
      key: 'createdAt',
      name: '등록일시',
      exp: (item) => moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss'),
      sort: (item) => item.createdAt,
      width: 160,
      align: 'center',
      onPress: (item) => navigate(`/tanks/${item.rid}`),
    },
    {
      key: 'createdBy',
      name: '등록자',
      exp: (item) => item.createdBy.name,
      sort: (item) => item.createdBy.name,
      width: 80,
      align: 'center',
      onPress: (item) => navigate(`/tanks/${item.rid}`),
    },
    {
      key: 'updatedAt',
      name: '수정일시',
      exp: (item) => moment(item.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
      sort: (item) => item.updatedAt,
      width: 160,
      align: 'center',
      onPress: (item) => navigate(`/tanks/${item.rid}`),
    },
    {
      key: 'updatedBy',
      name: '수정자',
      exp: (item) => item.updatedBy.name,
      sort: (item) => item.updatedBy.name,
      width: 80,
      align: 'center',
      onPress: (item) => navigate(`/tanks/${item.rid}`),
    },
    // {
    //   key: 'retiredAt',
    //   name: '폐기일시',
    //   exp: (item) => (item.retiredAt ? moment(item.retiredAt).format('YYYY-MM-DD HH:mm:ss') : '-'),
    //   width: 160,
    //   align: 'center',
    //   onPress: (item) => navigate(`/tanks/${item.rid}`),
    // },
    // {
    //   key: 'retiredBy',
    //   name: '폐기자',
    //   exp: (item) => (item.retiredBy ? item.retiredBy.name : '-'),
    //   width: 80,
    //   align: 'center',
    //   onPress: (item) => navigate(`/tanks/${item.rid}`),
    // },
    {
      key: 'memo',
      name: '메모',
      exp: (item) => <div className={cx('ellipsis')}>{item.memo}</div>,
      sort: (item) => item.memo,
      width: 200,
      onPress: (item) => navigate(`/tanks/${item.rid}`),
    },
  ];

  const filteredTanks = useMemo(() => {
    if (tanks === undefined) return;

    return tanks
      .filter((tank) => {
        const useStatus = searchParams.get('useStatus') ?? '';

        if (useStatus !== '') {
          return tank.useStatus === useStatus;
        }
        return true;
      })
      .filter((tank) => {
        const chargeStatus = searchParams.get('chargeStatus') ?? '';

        if (chargeStatus !== '') {
          return tank.chargeStatus === chargeStatus;
        }
        return true;
      })
      .filter((tank) => {
        const velveManufacturer = searchParams.get('velveManufacturer') ?? '';

        if (velveManufacturer !== '') {
          return tank.velveManufacturer === velveManufacturer;
        }
        return true;
      })
      .filter((tank) => {
        const customer = searchParams.get('customer')?.trim() ?? '';

        if (customer !== '') {
          return tank.deliveryInfo?.deliveryCompany.toLowerCase().includes(customer.toLowerCase());
        }
        return true;
      });
  }, [tanks, location]);

  return (
    <div className={cx('container')}>
      <Controlbar>
        <div className={cx('total')}>{filteredTanks && `총 ${commaNumber(filteredTanks.length)}건`}</div>
        <div>
          <select
            name="useStatus"
            value={filters?.useStatus ?? ''}
            onChange={handleChangeFilter}
            className={cx('select')}>
            <option value="">가용상태</option>
            <option value="DRAFT">임시</option>
            <option value="READY">준비</option>
            <option value="INUSE">사용</option>
            <option value="INSPECTING">검사</option>
            <option value="RETIRED">폐기</option>
          </select>
          <select
            name="chargeStatus"
            value={filters?.chargeStatus ?? ''}
            onChange={handleChangeFilter}
            className={cx('select')}>
            <option value="">충전상태</option>
            <option value="CHARGED">충전</option>
            <option value="UNCHARGED">미충전</option>
          </select>
          <select
            name="velveManufacturer"
            value={filters?.velveManufacturer ?? ''}
            onChange={handleChangeFilter}
            className={cx('select')}>
            <option value="">밸브 제조사</option>
            <option value="태광">태광</option>
            <option value="영도">영도</option>
          </select>
          <input
            type="text"
            name="customer"
            value={filters?.customer ?? ''}
            onChange={handleChangeFilter}
            placeholder="주문고객"
            className={cx('field')}
          />
          <Button title="엑셀 다운로드" onClick={download} />
          <Button title="조회" onClick={searchWithParams} style={{ marginLeft: 8 }} />
        </div>
      </Controlbar>
      <ContentWrapper>
        <Table ref={tableRef} useCheck columns={columns} data={filteredTanks} style={{ minWidth: 3200 }} />
        <CodePanel code={codeForPanel} close={() => setCodeForPanel(null)} />
      </ContentWrapper>
      <Controlbar>
        <div>
          <Button title="삭제" onClick={doRemove} />
          <Button title="폐기" onClick={doRetire} />
        </div>
        <div>
          <Button title="QR코드 출력" onClick={preparePrint} />
          <Button title="신규" onClick={moveToForm} />
        </div>
      </Controlbar>
      {showPaper && <Print data={selectedTanks.current} close={() => setShowPaper(false)} />}
    </div>
  );
};

export default Page;
