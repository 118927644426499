import React, { useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import commaNumber from 'comma-number';
import { ResponsivePie } from '@nivo/pie';

import styles from '../../../styles/card.module.scss';
import Table from '../../../components/ui/Table';
import TankSizeSelect from './TankSizeSelect';

const cx = classNames.bind(styles);

const ByUseStatus = ({ data: tanks }) => {
  const [tankSize, setTankSize] = useState();

  const handleChangeTankSize = (e) => {
    setTankSize(Number(e.target.value));
  };

  const columns = [
    {
      key: 'DRAFT',
      name: '임시',
      exp: (item) => commaNumber(item.DRAFT),
      align: 'center',
      style: { flex: 1 },
    },
    {
      key: 'READY',
      name: '준비',
      exp: (item) => commaNumber(item.READY),
      align: 'center',
      style: { flex: 1 },
    },
    {
      key: 'INUSE',
      name: '사용',
      exp: (item) => commaNumber(item.INUSE),
      align: 'center',
      style: { flex: 1 },
    },
    {
      key: 'INSPECTING',
      name: '검사',
      exp: (item) => commaNumber(item.INSPECTING),
      align: 'center',
      style: { flex: 1 },
    },
    {
      key: 'RETIRED',
      name: '폐기',
      exp: (item) => commaNumber(item.RETIRED),
      align: 'center',
      style: { flex: 1 },
    },
  ];

  const tableData = useMemo(() => {
    // 선택된 사이즈의 용기
    const filteredTanks = tanks?.filter((tank) => (tankSize ? tankSize === tank.size : true)) ?? [];

    const nextData = {
      DRAFT: filteredTanks.filter((tank) => tank.useStatus === 'DRAFT').length ?? 0,
      READY: filteredTanks.filter((tank) => tank.useStatus === 'READY').length ?? 0,
      INUSE: filteredTanks.filter((tank) => tank.useStatus === 'INUSE').length ?? 0,
      INSPECTING: filteredTanks.filter((tank) => tank.useStatus === 'INSPECTING').length ?? 0,
      RETIRED: filteredTanks.filter((tank) => tank.useStatus === 'RETIRED').length ?? 0,
    };
    return [nextData];
  }, [tanks, tankSize]);

  const chartData = useMemo(() => {
    // 선택된 사이즈의 용기
    const filteredTanks = tanks?.filter((tank) => (tankSize ? tankSize === tank.size : true)) ?? [];

    return [
      {
        id: '임시',
        value: filteredTanks?.filter((tank) => tank.useStatus === 'DRAFT').length ?? 0,
      },
      {
        id: '준비',
        value: filteredTanks?.filter((tank) => tank.useStatus === 'READY').length ?? 0,
      },
      {
        id: '사용',
        value: filteredTanks?.filter((tank) => tank.useStatus === 'INUSE').length ?? 0,
      },
      {
        id: '검사',
        value: filteredTanks?.filter((tank) => tank.useStatus === 'INSPECTING').length ?? 0,
      },
      {
        id: '폐기',
        value: filteredTanks?.filter((tank) => tank.useStatus === 'RETIRED').length ?? 0,
      },
    ];
  }, [tanks, tankSize]);

  return (
    <div className={cx('container')}>
      <div className={cx('header')}>
        <div className={cx('title')}>가용상태별</div>
        <div className={cx('options')}>
          <TankSizeSelect value={tankSize} onChange={handleChangeTankSize} className={cx('select')} />
        </div>
      </div>
      <div className={cx('body')}>
        <Table absoluteFill={false} columns={columns} data={tableData} />
        <div className={cx('chartWrapper')}>
          <ResponsivePie
            data={chartData}
            margin={{ top: 16, right: 0, bottom: 0, left: 0 }}
            innerRadius={0.5}
            padAngle={0.8}
            cornerRadius={4}
            colors={{ scheme: 'set1' }}
            arcLabelsTextColor="white"
            arcLinkLabelsDiagonalLength={8}
          />
        </div>
      </div>
    </div>
  );
};

export default ByUseStatus;
