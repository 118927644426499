import React, { useState, useMemo } from 'react';
import classNames from 'classnames/bind';
import moment from 'moment';
import useMountEffect from '@restart/hooks/useMountEffect';
import { useParams, useNavigate } from 'react-router-dom';

import styles from '../../styles/checklist.module.scss';
import API from '../../helpers/API';
import Controlbar from '../../components/ui/Controlbar';
import Button from '../../components/ui/Button';
import FormRow from '../../components/ui/FormRow';
import ContentWrapper from '../../components/ui/ContentWrapper';
import Checkbox from '../../components/ui/Checkbox';
import PrintChecklist from './PrintChecklist';
import { getDecodedSign } from '../../utils/SignDecoder';
import Breadcrumb from '../../components/ui/Breadcrumb';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const { rid } = useParams();
  const [terms, setTerms] = useState();
  const [order, setOrder] = useState();
  const [checklist, setChecklist] = useState();
  const [showPaper, setShowPaper] = useState(false);

  useMountEffect(() => {
    // 체크리스트 항목 조회
    API.get('/checklist').then(({ success, data }) => {
      if (success) {
        setTerms(data);
      }
    });

    // 체크리스트 기록 조회
    API.get(`/orders/${rid}/checklist`).then(({ success, data }) => {
      if (success && data.checklistAnswers.length > 0) {
        setChecklist(data);
      }
    });

    // API: 주문 상세 조회
    API.get(`/orders/${rid}`).then(({ success, data }) => {
      if (success) {
        setOrder(data);
      }
    });
  });

  const preparePrint = () => {
    setShowPaper(true);
  };

  const data = useMemo(() => {
    if (terms === undefined) return;
    if (checklist === undefined) return;

    // 체크리스트 항목, 답변 매핑
    const mappedChecklist = terms.map((term) => {
      const { agreement } = checklist.checklistAnswers.find((item) => item.termRid === term.rid);

      return {
        question: term.question,
        agreement,
      };
    });

    return {
      checklist: mappedChecklist,
      sign: getDecodedSign(checklist.sign.data),
      createdBy: checklist.createdBy.name,
      createdAt: moment(checklist.createdAt).format('YYYY-MM-DD HH:mm:ss'),
    };
  }, [checklist, terms]);

  if (data === undefined) return;

  return (
    <div className={cx('container')}>
      <Controlbar>
        <div>
          <Breadcrumb />
        </div>
      </Controlbar>
      <ContentWrapper>
        <FormRow title="점검자">{data.createdBy}</FormRow>
        <FormRow title="점검일시">{data.createdAt}</FormRow>
        <FormRow title="응답">
          <div className={cx('checklistWrapper')}>
            <div className={cx('header')}>
              <div className={cx('term')}>항목</div>
              <div className={cx('answers')}>
                <div className={cx('answer')}>적합</div>
                <div className={cx('answer')}>부적합</div>
              </div>
            </div>
            <div className={cx('rows')}>
              {data.checklist.map((item, index) => (
                <div key={index} className={cx('row')}>
                  <div className={cx('term')}>{`${index + 1}. ${item.question}`}</div>
                  <div className={cx('answers')}>
                    <div className={cx('answer')}>
                      <Checkbox checked={item.agreement} />
                    </div>
                    <div className={cx('answer')}>
                      <Checkbox checked={!item.agreement} />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </FormRow>
        <FormRow title="서명">
          <div className={cx('signatureWrapper')}>
            <img src={data.sign} alt="signature" />
          </div>
        </FormRow>
      </ContentWrapper>
      <Controlbar>
        <Button title="뒤로" onClick={() => navigate(-1)} />
        <Button title="출력" onClick={preparePrint} />
      </Controlbar>
      {showPaper && <PrintChecklist checklist={data} order={order} close={() => setShowPaper(false)} />}
    </div>
  );
};

export default Page;
