import React, { useState } from 'react';
import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';

import styles from '../../styles/form.module.scss';
import API from '../../helpers/API';
import TextInput from '../../components/ui/TextInput';
import Textarea from '../../components/ui/Textarea';
import Button from '../../components/ui/Button';
import Controlbar from '../../components/ui/Controlbar';
import ContentWrapper from '../../components/ui/ContentWrapper';
import FormSectionTitle from '../../components/ui/FormSectionTitle';
import FormRow from '../../components/ui/FormRow';
import Select from '../../components/ui/Select';
import Breadcrumb from '../../components/ui/Breadcrumb';

const cx = classNames.bind(styles);

const Page = () => {
  const { rid } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState();

  useMountEffect(() => {
    API.get(`/users/${rid}`).then(({ success, data }) => {
      if (success) {
        setUser(data);
      }
    });
  });

  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const moveToBack = () => {
    navigate(-1);
  };

  const doRemove = () => {
    if (!window.confirm('사용자를 삭제하시겠습니까?')) {
      return;
    }

    API.delete(`/users/${rid}`).then(({ success }) => {
      if (success) {
        navigate('/users');
      } else {
        toast.error('삭제를 실패했습니다.');
      }
    });
  };

  const validation = () => {
    if (user.name.trim() === '') {
      toast.error('성명이 입력되지 않았습니다.');
      return false;
    }

    return true;
  };

  const doSave = () => {
    if (!validation()) {
      return;
    }

    const data = {
      name: user.name,
      role: user.role,
      email: user.email,
      phone: user.phone,
      memo: user.memo,
    };

    API.patch(`/users/${rid}`, data).then(({ success }) => {
      if (success) {
        navigate('/users');
      } else {
        toast.error('저장을 실패했습니다.');
      }
    });
  };

  return (
    <div className={cx('container')}>
      <Controlbar>
        <div>
          <Breadcrumb />
        </div>
      </Controlbar>
      <ContentWrapper>
        {user && (
          <div>
            <FormSectionTitle title="기본정보" />
            <FormRow title="아이디" required>
              <TextInput width={240} value={user.id} readOnly />
            </FormRow>
            <FormRow title="성명" required>
              <TextInput name="name" width={120} value={user.name} placeholder="성명" onChange={handleChange} />
            </FormRow>
            <FormRow title="역할" required>
              <Select width={120} name="role" value={user.role} onChange={handleChange}>
                <option value="SYSTEM">시스템관리자</option>
                <option value="ADMIN">통합관리자</option>
                <option value="MANAGER">충전관리자</option>
                <option value="CHARGER">충전원</option>
                <option value="DELIVERER">배송원</option>
                <option value="SALES">영업사원</option>
              </Select>
            </FormRow>
            <FormRow title="이메일">
              <TextInput name="email" width={320} value={user.email} placeholder="이메일" onChange={handleChange} />
            </FormRow>
            <FormRow title="전화번호">
              <TextInput name="phone" width={240} value={user.phone} placeholder="전화번호" onChange={handleChange} />
            </FormRow>
            <FormSectionTitle title="부가정보" />
            <FormRow title="메모">
              <Textarea name="memo" value={user.memo} placeholder="메모" height={120} onChange={handleChange} />
            </FormRow>
            <FormRow title="등록일시">
              <div className={cx('value')}>{moment(user.createdAt).format('YYYY-MM-DD HH:mm:ss')}</div>
            </FormRow>
            <FormRow title="등록자">
              <div className={cx('value')}>{user.createdBy.name}</div>
            </FormRow>
            <FormRow title="수정일시">
              <div className={cx('value')}>{moment(user.updatedAt).format('YYYY-MM-DD HH:mm:ss')}</div>
            </FormRow>
            <FormRow title="수정자">
              <div className={cx('value')}>{user.updatedBy.name}</div>
            </FormRow>
          </div>
        )}
      </ContentWrapper>
      <Controlbar>
        <div>
          <Button title="뒤로" onClick={moveToBack} />
          <Button title="삭제" onClick={doRemove} />
        </div>
        <Button title="저장" onClick={doSave} />
      </Controlbar>
    </div>
  );
};

export default Page;
