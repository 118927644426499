import React, { useRef } from 'react';
import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';

import styles from './index.module.scss';
import API, { setAuthToken } from '../../helpers/API';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const usernameRef = useRef();
  const passwordRef = useRef();

  useMountEffect(() => {
    // 화면 로딩 후, 아이디 입력 필드 포커스
    usernameRef.current.focus();

    usernameRef.current.value = 'admin';
    passwordRef.current.value = '123qwer@';
  });

  const pressEnter = (e) => {
    if (e.key === 'Enter') {
      switch (e.target.name) {
        case 'username':
          passwordRef.current.focus();
          break;

        case 'password':
          doLogin();
          break;

        default:
          break;
      }
    }
  };

  // 로그인
  const doLogin = async () => {
    const { success, data } = await API.post('/login', {
      id: usernameRef.current.value,
      password: passwordRef.current.value,
    });

    if (success && data.accessToken) {
      setAuthToken(data.accessToken);
      localStorage.setItem('@h2logis/role', data.role);
      navigate('/', { replace: true });
    }
  };

  return (
    <div className={cx('container')}>
      <div className={cx('logo')} />
      <form className={cx('fieldsWrapper')}>
        <div className={cx('fieldWrapper')}>
          <input
            ref={usernameRef}
            name="username"
            type="text"
            placeholder="아이디"
            onKeyPress={pressEnter}
            className={cx('field')}
            autoComplete="username"
          />
        </div>
        <div className={cx('fieldWrapper')}>
          <input
            ref={passwordRef}
            name="password"
            type="password"
            placeholder="비밀번호"
            onKeyPress={pressEnter}
            className={cx('field')}
            autoComplete="current-password"
          />
        </div>
      </form>
      <div className={cx('button')} onClick={doLogin}>
        로그인
      </div>
    </div>
  );
};

export default Page;
