import React, { useState } from 'react';
import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import commaNumber from 'comma-number';
import moment from 'moment';

import styles from '../../styles/list.module.scss';
import API from '../../helpers/API';
import Button from '../../components/ui/Button';
import Table from '../../components/ui/Table';
import Controlbar from '../../components/ui/Controlbar';
import ContentWrapper from '../../components/ui/ContentWrapper';
import { getReclaimStatus } from '../../utils/CodeMapper';
import { downloadExcelFile } from '../../utils/FileDownloader';
import { showLoading } from '../../store/loadingSlice';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [total, setTotal] = useState(0);
  const [reclaims, setReclaims] = useState();

  useMountEffect(() => {
    search();
  });

  const search = () => {
    dispatch(showLoading(true));

    // API: 회수 내역 조회
    API.get('/reclaims')
      .then(({ success, data }) => {
        if (success) {
          setTotal(data.totalCount);
          setReclaims(data.reclaims);
        }
      })
      .finally(() => dispatch(showLoading(false)));
  };

  const download = () => {
    const records = reclaims.map((reclaim) => {
      return {
        A: getReclaimStatus(reclaim.status),
        B: reclaim.quantity,
        C: reclaim.customerName,
        D: `${reclaim.customerAddress} ${reclaim.customerAddressDetail}`,
        E: moment(reclaim.createdAt).format('YYYY-MM-DD HH:mm:ss'),
        F: reclaim.createdBy.name,
        G: moment(reclaim.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
        H: reclaim.updatedBy.name,
      };
    });

    downloadExcelFile(
      [
        {
          A: '상태',
          B: '수량',
          C: '성명',
          D: '주소',
          E: '등록일시',
          F: '등록자',
          G: '수정일시',
          H: '수정자',
        },
      ],
      records,
      `회수내역-${moment().format('YYYYMMDDHHmmss')}`
    );
  };

  const moveToForm = () => {
    navigate('/reclaims/new');
  };

  const columns = [
    {
      key: 'rid',
      name: 'RID',
      exp: (item) => <span className={cx('monospace')}>{item.rid.substring(0, 8)}</span>,
      width: 104,
      align: 'center',
      hidden: true,
      onPress: (item) => {
        const element = document.createElement('textarea');
        element.value = item.rid;
        document.body.appendChild(element);

        element.select();
        document.execCommand('copy');
        document.body.removeChild(element);
      },
    },
    {
      key: 'status',
      name: '상태',
      exp: (item) => getReclaimStatus(item.status),
      width: 80,
      align: 'center',
      onPress: (item) => navigate(`/reclaims/${item.rid}`),
    },
    {
      key: 'quantity',
      name: '수량',
      exp: (item) => item.quantity,
      width: 80,
      align: 'center',
      onPress: (item) => navigate(`/reclaims/${item.rid}`),
    },
    {
      key: 'name',
      name: '성명',
      exp: (item) => <div className={cx('ellipsis')}>{item.customerName}</div>,
      width: 120,
      onPress: (item) => navigate(`/reclaims/${item.rid}`),
    },
    {
      key: 'address',
      name: '주소',
      exp: (item) => (
        <div className={cx('ellipsis')}>
          {item.customerAddress} {item.customerAddressDetail}
        </div>
      ),
      style: { flex: 1 },
      onPress: (item) => navigate(`/reclaims/${item.rid}`),
    },
    {
      key: 'createdAt',
      name: '등록일시',
      exp: (item) => moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss'),
      width: 160,
      align: 'center',
      onPress: (item) => navigate(`/reclaims/${item.rid}`),
    },
    {
      key: 'createdBy',
      name: '등록자',
      exp: (item) => item.createdBy.name,
      width: 80,
      align: 'center',
      onPress: (item) => navigate(`/reclaims/${item.rid}`),
    },
    {
      key: 'updatedAt',
      name: '수정일시',
      exp: (item) => moment(item.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
      width: 160,
      align: 'center',
      onPress: (item) => navigate(`/reclaims/${item.rid}`),
    },
    {
      key: 'updatedBy',
      name: '수정자',
      exp: (item) => item.updatedBy.name,
      width: 80,
      align: 'center',
      onPress: (item) => navigate(`/reclaims/${item.rid}`),
    },
  ];

  return (
    <div className={cx('container')}>
      <Controlbar>
        <div className={cx('total')}>총 {commaNumber(total)}건</div>
        <div>
          <Button title="엑셀 다운로드" onClick={download} />
          <Button title="조회" onClick={search} />
        </div>
      </Controlbar>
      <ContentWrapper>
        <Table columns={columns} data={reclaims} />
      </ContentWrapper>
      <Controlbar>
        <div />
        <div>
          <Button title="신규" onClick={moveToForm} />
        </div>
      </Controlbar>
    </div>
  );
};

export default Page;
