import React, { useState } from 'react';
import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';

import styles from '../../styles/form.module.scss';
import API from '../../helpers/API';
import TextInput from '../../components/ui/TextInput';
import Textarea from '../../components/ui/Textarea';
import Button from '../../components/ui/Button';
import Controlbar from '../../components/ui/Controlbar';
import ContentWrapper from '../../components/ui/ContentWrapper';
import FormSectionTitle from '../../components/ui/FormSectionTitle';
import FormRow from '../../components/ui/FormRow';
import Breadcrumb from '../../components/ui/Breadcrumb';
import { getAddress } from '../../utils/AddressFinder';

const cx = classNames.bind(styles);

const Page = () => {
  const { rid } = useParams();
  const navigate = useNavigate();
  const [location, setLocation] = useState();

  useMountEffect(() => {
    API.get(`/locations/${rid}`).then(({ success, data }) => {
      if (success) {
        setLocation(data);
      }
    });
  });

  const handleChange = (e) => {
    setLocation({
      ...location,
      [e.target.name]: e.target.value,
    });
  };

  const findAddress = () => {
    getAddress().then((data) => setLocation({ ...location, address: data.address }));
  };

  const moveToBack = () => {
    navigate(-1);
  };

  const doRemove = () => {
    if (!window.confirm('거점을 삭제하시겠습니까?')) {
      return;
    }

    API.delete(`/locations/${rid}`).then(({ success }) => {
      if (success) {
        navigate('/locations');
      } else {
        toast.error('삭제를 실패했습니다.');
      }
    });
  };

  const validation = () => {
    if (location.name.trim() === '') {
      toast.error('거점명이 입력되지 않았습니다.');
      return false;
    }
    if (location.address.trim() === '') {
      toast.error('주소가 입력되지 않았습니다.');
      return false;
    }
    if (location.phone.trim() === '') {
      toast.error('전화번호가 입력되지 않았습니다.');
      return false;
    }
    if (location.latitude.trim() === '' || location.longitude.trim() === '') {
      toast.error('위치 정보가 입력되지 않았습니다.');
      return false;
    }
    if (location.managerName.trim() === '') {
      toast.error('담당자명이 입력되지 않았습니다.');
      return false;
    }
    if (location.managerPhone.trim() === '') {
      toast.error('담당자 전화번호가 입력되지 않았습니다.');
      return false;
    }

    return true;
  };

  const doSave = () => {
    if (!validation()) {
      return;
    }

    const data = {
      name: location.name,
      address: location.address,
      addressDetail: location.addressDetail,
      phone: location.phone,
      latitude: location.latitude,
      longitude: location.longitude,
      managerName: location.managerName,
      managerPhone: location.managerPhone,
      memo: location.memo,
    };

    API.patch(`/locations/${rid}`, data).then(({ success }) => {
      if (success) {
        navigate('/locations');
      } else {
        toast.error('저장을 실패했습니다.');
      }
    });
  };

  return (
    <div className={cx('container')}>
      <Controlbar>
        <div>
          <Breadcrumb />
        </div>
      </Controlbar>
      <ContentWrapper>
        {location && (
          <div>
            <FormSectionTitle title="기본정보" />
            <FormRow title="거점명" required>
              <TextInput name="name" width={240} value={location.name} placeholder="거점명" onChange={handleChange} />
            </FormRow>
            <FormRow title="주소" required>
              <TextInput name="address" width={400} value={location.address} placeholder="주소" readOnly disabled />
              <TextInput
                name="addressDetail"
                width={320}
                value={location.addressDetail}
                placeholder="상세주소 (선택)"
                onChange={handleChange}
                style={{ marginLeft: 8 }}
              />
              <Button variant="bordered" title="주소 찾기" onClick={findAddress} style={{ marginLeft: 8 }} />
            </FormRow>
            <FormRow title="전화번호" required>
              <TextInput
                name="phone"
                width={240}
                value={location.phone}
                placeholder="전화번호"
                onChange={handleChange}
              />
            </FormRow>
            <FormRow title="위치" required>
              <TextInput
                name="latitude"
                width={160}
                value={location.latitude}
                placeholder="위도"
                onChange={handleChange}
              />
              <TextInput
                name="longitude"
                width={160}
                value={location.longitude}
                placeholder="경도"
                onChange={handleChange}
                style={{ marginLeft: 8 }}
              />
            </FormRow>
            <FormRow title="담당자명" required>
              <TextInput
                name="managerName"
                width={120}
                value={location.managerName}
                placeholder="담당자명"
                onChange={handleChange}
              />
            </FormRow>
            <FormRow title="담당자 전화번호" required>
              <TextInput
                name="managerPhone"
                width={240}
                value={location.managerPhone}
                placeholder="담당자 전화번호"
                onChange={handleChange}
              />
            </FormRow>
            <FormSectionTitle title="부가정보" />
            <FormRow title="메모">
              <Textarea name="memo" value={location.memo} placeholder="메모" height={120} onChange={handleChange} />
            </FormRow>
            <FormRow title="등록일시">
              <div className={cx('value')}>{moment(location.createdAt).format('YYYY-MM-DD HH:mm:ss')}</div>
            </FormRow>
            <FormRow title="등록자">
              <div className={cx('value')}>{location.createdBy.name}</div>
            </FormRow>
            <FormRow title="수정일시">
              <div className={cx('value')}>{moment(location.updatedAt).format('YYYY-MM-DD HH:mm:ss')}</div>
            </FormRow>
            <FormRow title="수정자">
              <div className={cx('value')}>{location.updatedBy.name}</div>
            </FormRow>
          </div>
        )}
      </ContentWrapper>
      <Controlbar>
        <div>
          <Button title="뒤로" onClick={moveToBack} />
          <Button title="삭제" onClick={doRemove} />
        </div>
        <Button title="저장" onClick={doSave} />
      </Controlbar>
    </div>
  );
};

export default Page;
