import React, { useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import commaNumber from 'comma-number';

import styles from '../../../styles/card.module.scss';
import Table from '../../../components/ui/Table';
import TankSizeSelect from './TankSizeSelect';

const cx = classNames.bind(styles);

const ByCustomer = ({ data: tanks }) => {
  const [tankSize, setTankSize] = useState();

  const handleChangeTankSize = (e) => {
    setTankSize(Number(e.target.value));
  };

  const columns = [
    {
      key: 'customer',
      name: '주문고객명',
      exp: (item) => <div className={cx('ellipsis')}>{item.name}</div>,
      style: { flex: 1 },
    },
    {
      key: 'count',
      name: '용기수',
      exp: (item) => commaNumber(item.count),
      width: 80,
      align: 'center',
    },
  ];

  const data = useMemo(() => {
    if (tanks === undefined) return;

    // 선택된 사이즈의 용기
    const filteredTanks = tanks.filter((tank) => (tankSize ? tankSize === tank.size : true));

    const nextCustomers = [];
    filteredTanks.forEach((tank) => {
      if (tank.deliveryInfo === undefined) return;

      const found = nextCustomers.find((customer) => {
        return customer.name === tank.deliveryInfo.deliveryCompany;
      });
      if (found) {
        found.count += 1;
      } else {
        nextCustomers.push({
          name: tank.deliveryInfo.deliveryCompany,
          count: 1,
        });
      }
    });

    nextCustomers.sort((a, b) => (a.name < b.name ? -1 : 1));
    return nextCustomers;
  }, [tanks, tankSize]);

  return (
    <div className={cx('container')}>
      <div className={cx('header')}>
        <div className={cx('title')}>고객별</div>
        <div className={cx('options')}>
          <TankSizeSelect value={tankSize} onChange={handleChangeTankSize} className={cx('select')} />
        </div>
      </div>
      <div className={cx('body')}>
        <Table columns={columns} data={data} />
      </div>
    </div>
  );
};

export default ByCustomer;
