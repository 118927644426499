import React, { useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import commaNumber from 'comma-number';
import { ResponsivePie } from '@nivo/pie';

import styles from '../../../styles/card.module.scss';
import Table from '../../../components/ui/Table';
import TankSizeSelect from './TankSizeSelect';

const cx = classNames.bind(styles);

const ByChargeStatus = ({ data: tanks }) => {
  const [tankSize, setTankSize] = useState();

  const handleChangeTankSize = (e) => {
    setTankSize(Number(e.target.value));
  };

  const columns = [
    {
      key: 'UNCHARGED',
      name: '미충전',
      exp: (item) => commaNumber(item.UNCHARGED),
      align: 'center',
      style: { flex: 1 },
    },
    {
      key: 'CHARGED',
      name: '충전',
      exp: (item) => commaNumber(item.CHARGED),
      align: 'center',
      style: { flex: 1 },
    },
    {
      key: 'TOTAL',
      name: '합계',
      exp: (item) => commaNumber(item.TOTAL),
      align: 'center',
      style: { flex: 1 },
    },
  ];

  const readyTanks = useMemo(() => {
    if (tanks === undefined) return [];

    return tanks
      .filter((tank) => tank.useStatus === 'READY')
      .filter((tank) => (tankSize ? tankSize === tank.size : true));
  }, [tanks, tankSize]);

  const tableData = useMemo(() => {
    const nextData = {
      UNCHARGED: readyTanks.filter((tank) => tank.chargeStatus === 'UNCHARGED').length,
      CHARGED: readyTanks.filter((tank) => tank.chargeStatus === 'CHARGED').length,
      TOTAL: readyTanks.length,
    };
    return [nextData];
  }, [readyTanks]);

  const chartData = useMemo(() => {
    return [
      {
        id: '미충전',
        value: readyTanks.filter((tank) => tank.chargeStatus === 'UNCHARGED').length ?? 0,
      },
      {
        id: '충전',
        value: readyTanks.filter((tank) => tank.chargeStatus === 'CHARGED').length ?? 0,
      },
    ];
  }, [readyTanks]);

  return (
    <div className={cx('container')}>
      <div className={cx('header')}>
        <div className={cx('title')}>준비 중 충전상태별</div>
        <div className={cx('options')}>
          <TankSizeSelect value={tankSize} onChange={handleChangeTankSize} className={cx('select')} />
        </div>
      </div>
      <div className={cx('body')}>
        <Table absoluteFill={false} columns={columns} data={tableData} />
        <div className={cx('chartWrapper')}>
          <ResponsivePie
            data={chartData}
            margin={{ top: 16, right: 0, bottom: 0, left: 0 }}
            innerRadius={0.5}
            padAngle={0.8}
            cornerRadius={4}
            colors={{ scheme: 'set1' }}
            arcLabelsTextColor="white"
            arcLinkLabelsDiagonalLength={8}
          />
        </div>
      </div>
    </div>
  );
};

export default ByChargeStatus;
