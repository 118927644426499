import React, { useState } from 'react';
import moment from 'moment';
import classNames from 'classnames/bind';
import useMountEffect from '@restart/hooks/useMountEffect';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import { toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';
import { IoArrowForwardOutline } from 'react-icons/io5';

import styles from '../../styles/form.module.scss';
import Button from '../../components/ui/Button';
import Table from '../../components/ui/Table';
import Controlbar from '../../components/ui/Controlbar';
import ContentWrapper from '../../components/ui/ContentWrapper';
import FormSectionTitle from '../../components/ui/FormSectionTitle';
import FormRow from '../../components/ui/FormRow';
import Select from '../../components/ui/Select';
import Breadcrumb from '../../components/ui/Breadcrumb';
import API from '../../helpers/API';
import { phoneNumber } from '../../utils/Formatter';
import { getOrderStatus, getShippingType } from '../../utils/CodeMapper';

const cx = classNames.bind(styles);

const Page = () => {
  const { rid } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState();
  const [locations, setLocations] = useState();
  const [shippings, setShippings] = useState();
  const [outgoingLocation, setOutgoingLocation] = useState();
  const [incomingLocation, setIncomingLocation] = useState();
  const [isTurnTo, setTurnTo] = useState({
    outgoing: false,
    incoming: false,
  });

  useMountEffect(() => {
    // API: 거점 내역 조회
    API.get('/locations').then(({ success, data }) => {
      if (success) {
        setLocations(data.locations);
      }
    });

    // API: 주문 상세 조회
    API.get(`/orders/${rid}`).then(({ success, data }) => {
      if (success) {
        setOrder(data);
        getShippings();
      }
    });
  });

  useUpdateEffect(() => {
    if (shippings === undefined) return;

    if (shippings.length === 0) {
      setTurnTo({
        outgoing: true,
        incoming: false,
      });
      return;
    }

    // 최종 배송 이력
    const lastShipping = shippings[shippings.length - 1];
    setTurnTo({
      outgoing: lastShipping.type === 'INCOMING',
      incoming: lastShipping.type === 'OUTGOING',
    });
  }, [shippings]);

  useUpdateEffect(() => {
    if (locations === undefined) return;

    if (isTurnTo.outgoing) {
      // API: 용기 상세 조회
      API.get(`/tanks/${order.orderTanks[0].tank.rid}`).then(({ success, data }) => {
        if (success) {
          setOutgoingLocation(data.location);
          setIncomingLocation(locations.find((location) => location.rid !== data.location.rid));
        }
      });
    }

    if (isTurnTo.incoming) {
      const lastShipping = shippings[shippings.length - 1];
      setOutgoingLocation(lastShipping.location);
      setIncomingLocation(lastShipping.destination);
    }
  }, [isTurnTo, locations]);

  const getShippings = () => {
    // API: 주문배송 이력 조회
    API.get(`/orders/${rid}/shipping`).then(({ success, data }) => {
      setShippings(success ? data : []);
    });
  };

  const handleChangeLocation = (e) => {
    setIncomingLocation(locations.find((location) => location.rid === e.target.value));
  };

  const saveOutgoing = () => {
    // API: 주문배송 출고
    API.post(`/orders/${rid}/outgoing`, { destinationRid: incomingLocation.rid }).then(({ success }) => {
      if (success) {
        getShippings();
      } else {
        toast.error('저장을 실패했습니다.');
      }
    });
  };

  const saveIncoming = () => {
    if (incomingLocation.name === '주문고객') {
      // API: 주문배송 고객 인도
      API.post(`/orders/${rid}/handover`, { inspectionSign: 'SYSTEM' }).then(({ success }) => {
        if (success) {
          getShippings();
        } else {
          toast.error('저장을 실패했습니다.');
        }
      });
      return;
    }

    // API: 주문배송 입고
    API.post(`/orders/${rid}/incoming`, { locationRid: incomingLocation.rid }).then(({ success }) => {
      if (success) {
        getShippings();
      } else {
        toast.error('저장을 실패했습니다.');
      }
    });
  };

  const moveToBack = () => {
    navigate(-1);
  };

  const moveToChecklist = () => {
    // 배송 이력 중 마지막 이력
    const lastShipping = shippings[shippings.length - 1];

    if (lastShipping.type !== 'DELIVERED') {
      toast.error('배송이 완료되지 않았습니다.');
      return;
    }

    // 배송 완료된 시점이 6월 30일 이전인 건은 체크리스트 존재하지 않음
    if (moment(lastShipping.createdAt).isBefore(moment('20230701'))) {
      toast.error('2023년 6월 30일 이전 주문 건은 체크리스트가 존재하지 않습니다.');
      return;
    }

    navigate(`/orders/${order.rid}/shipping/checklist`);
  };

  const customerColumns = [
    {
      key: 'customerName',
      name: '성명',
      exp: (item) => item.customerName,
      style: { flex: 1 },
    },
    {
      key: 'customerCompany',
      name: '회사명',
      exp: (item) => item.customerCompany,
      style: { flex: 1 },
    },
    {
      key: 'customerPhone',
      name: '전화번호',
      exp: (item) => phoneNumber(item.customerPhone),
      style: { flex: 1 },
    },
  ];

  const deliveryColumns = [
    {
      key: 'deliveryName',
      name: '성명',
      exp: (item) => item.deliveryName,
      style: { flex: 1 },
    },
    {
      key: 'deliveryCompany',
      name: '회사명',
      exp: (item) => item.deliveryCompany,
      style: { flex: 1 },
    },
    {
      key: 'deliveryPhone',
      name: '전화번호',
      exp: (item) => phoneNumber(item.deliveryPhone),
      style: { flex: 1 },
    },
  ];

  const shippingColumns = [
    {
      key: 'seq',
      name: '순번',
      exp: (item) => item.seq,
      width: 80,
      align: 'center',
    },
    {
      key: 'type',
      name: '유형',
      exp: (item) => getShippingType(item.type),
      width: 120,
      align: 'center',
    },
    {
      key: 'location',
      name: '현재 위치',
      exp: (item) => item.location.name,
      style: { flex: 1 },
      align: 'center',
    },
    {
      key: 'destination',
      name: '다음 거점',
      exp: (item) => item.destination?.name ?? '-',
      style: { flex: 1 },
      align: 'center',
    },
    {
      key: 'createdAt',
      name: '처리일시',
      exp: (item) =>
        item.createdAt ? moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss') : moment().format('YYYY-MM-DD HH:mm:ss'),
      width: 160,
      align: 'center',
    },
    {
      key: 'createdBy',
      name: '처리자',
      exp: (item) => (item.createdBy ? item.createdBy.name : '-'),
      width: 80,
      align: 'center',
    },
  ];

  return (
    <div className={cx('container')}>
      <Controlbar>
        <div>
          <Breadcrumb />
        </div>
      </Controlbar>
      <ContentWrapper>
        {order && (
          <div>
            <FormSectionTitle title="주문정보" />
            <FormRow title="주문번호">
              <div className={cx('value')}>{order.originNo}</div>
            </FormRow>
            <FormRow title="주문일자">
              <div className={cx('value')}>{moment(order.orderDate, 'YYYYMMDD').format('YYYY-MM-DD')}</div>
            </FormRow>
            <FormRow title="상태">
              <div className={cx('value')}>{getOrderStatus(order.status)}</div>
            </FormRow>
            <FormRow title="수량">
              <div className={cx('value')}>{order.quantity}</div>
            </FormRow>
            <FormRow title="주문자">
              <div className={cx('tableWrapperCell')}>
                <div className={cx(['tableWrapper', 'single'])}>
                  <Table absoluteFill={false} columns={customerColumns} data={[order]} />
                </div>
              </div>
            </FormRow>
            <FormSectionTitle title="배송정보" />
            <FormRow title="수신자">
              <div className={cx('tableWrapperCell')}>
                <div className={cx(['tableWrapper', 'single'])}>
                  <Table absoluteFill={false} columns={deliveryColumns} data={[order]} />
                </div>
              </div>
            </FormRow>
            <FormRow title="주소">
              <div className={cx('value')}>
                {order.deliveryAddress} {order.deliveryAddressDetail}
              </div>
            </FormRow>
            <FormRow title="배송 이력">
              <div className={cx('tableWrapperCell')}>
                {isTurnTo.outgoing && (
                  <div className={cx('tableControl')}>
                    <Select width={240} name="status" readOnly value={outgoingLocation?.rid}>
                      {locations?.map((location) => (
                        <option
                          key={location.rid}
                          disabled={location.rid !== outgoingLocation?.rid}
                          value={location.rid}>
                          {location.name}
                        </option>
                      ))}
                    </Select>
                    <IoArrowForwardOutline size={20} className={cx('arrow')} />
                    <Select width={240} name="status" value={incomingLocation?.rid} onChange={handleChangeLocation}>
                      {locations?.map((location) => (
                        <option
                          key={location.rid}
                          disabled={location.rid === outgoingLocation?.rid}
                          value={location.rid}>
                          {location.name}
                        </option>
                      ))}
                    </Select>
                    <div className={cx(['button', 'text'])} onClick={saveOutgoing}>
                      출고
                    </div>
                  </div>
                )}
                {isTurnTo.incoming && (
                  <div className={cx('tableControl')}>
                    <Select width={240} name="status" readOnly value={outgoingLocation?.rid}>
                      {locations?.map((location, index) => (
                        <option key={index} disabled={location.rid !== outgoingLocation?.rid} value={location.rid}>
                          {location.name}
                        </option>
                      ))}
                    </Select>
                    <IoArrowForwardOutline size={20} className={cx('arrow')} />
                    <Select width={240} name="status" value={incomingLocation?.rid} onChange={handleChangeLocation}>
                      {locations?.map((location) => (
                        <option
                          key={location.rid}
                          disabled={location.rid !== incomingLocation?.rid}
                          value={location.rid}>
                          {location.name}
                        </option>
                      ))}
                    </Select>
                    <div className={cx(['button', 'text'])} onClick={saveIncoming}>
                      입고
                    </div>
                  </div>
                )}
                <div className={cx(['tableWrapper', 'multi'])}>
                  <Table columns={shippingColumns} data={shippings} />
                </div>
              </div>
            </FormRow>
          </div>
        )}
      </ContentWrapper>
      <Controlbar>
        <Button title="뒤로" onClick={moveToBack} />
        <Button title="체크리스트" onClick={moveToChecklist} />
      </Controlbar>
    </div>
  );
};

export default Page;
