import React, { useState, useMemo } from 'react';
import useMountEffect from '@restart/hooks/useMountEffect';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import Picker from 'rc-calendar/lib/Picker';
import Calendar from 'rc-calendar/lib/Calendar';
import koKR from 'rc-calendar/lib/locale/ko_KR';
import 'rc-calendar/assets/index.css';

import styles from '../../styles/form.module.scss';
import API from '../../helpers/API';
import TextInput from '../../components/ui/TextInput';
import Textarea from '../../components/ui/Textarea';
import Button from '../../components/ui/Button';
import Controlbar from '../../components/ui/Controlbar';
import ContentWrapper from '../../components/ui/ContentWrapper';
import FormSectionTitle from '../../components/ui/FormSectionTitle';
import FormRow from '../../components/ui/FormRow';
import FormRowInfo from '../../components/ui/FormRowInfo';
import Select from '../../components/ui/Select';
import Breadcrumb from '../../components/ui/Breadcrumb';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const [tank, setTank] = useState({
    qrcode: '',
    useStatus: 'DRAFT',
    chargeStatus: 'UNCHARGED',
    locationRid: '',
    type: '',
    size: '',
    region: '',
    serialNo: '',
    manufacturer: '',
    manufacturedDate: '',
    expireDate: '',
    velveSerialNo: '',
    velveManufacturer: '',
    velveVersion: '',
    velveExpireDate: '',
    memo: '',
  });
  const [locations, setLocations] = useState();

  useMountEffect(() => {
    // API: QR코드 발급
    API.get('/tanks/qrcode').then(({ success, data }) => {
      if (success) {
        setTank({
          ...tank,
          qrcode: data.qrcode,
        });
      }
    });

    // API: 거점 내역 조회
    API.get('/locations').then(({ success, data }) => {
      if (success) {
        setLocations(data.locations);
      }
    });
  });

  // 제조일자 변경 시, 용기 충전기한, 밸브 충전기한 갱신
  useUpdateEffect(() => {
    if (tank.manufacturedDate === '') return;

    setTank({
      ...tank,
      expireDate: tank.manufacturedDate.clone().add(expireYears, 'years'),
      velveExpireDate: tank.manufacturedDate.clone().add(5, 'years'),
    });
  }, [tank.manufacturedDate]);

  const handleChange = (e) => {
    setTank({
      ...tank,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeDate = (key, value) => {
    setTank({
      ...tank,
      [key]: value,
    });
  };

  const handleChangeUseStatus = (e) => {
    // 임시
    if (e.target.value === 'DRAFT') {
      setTank({
        ...tank,
        useStatus: 'DRAFT',
        chargeStatus: 'UNCHARGED',
        locationRid: '',
        type: '',
        size: '',
        region: '',
        manufacturer: '',
        manufacturedDate: '',
        expireDate: '',
        velveSerialNo: '',
        velveManufacturer: '',
        velveVersion: '',
        velveExpireDate: '',
      });
    }
    // 사용
    else if (e.target.value === 'INUSE') {
      setTank({
        ...tank,
        useStatus: 'INUSE',
        chargeStatus: 'CHARGED',
        locationRid: locations.find((location) => location.name === '주문고객').rid,
        manufacturedDate: moment(),
      });
    }
    // 기타
    else {
      setTank({
        ...tank,
        useStatus: e.target.value,
        chargeStatus: 'UNCHARGED',
        locationRid: '',
        manufacturedDate: moment(),
      });
    }
  };

  const moveToBack = () => {
    navigate(-1);
  };

  const validation = () => {
    // 임시 상태인 경우
    if (isDraft) {
      return true;
    }
    if (tank.locationRid === '') {
      toast.error('현재 위치가 선택되지 않았습니다.');
      return false;
    }
    if (tank.type === '') {
      toast.error('유형이 선택되지 않았습니다.');
      return false;
    }
    if (tank.size === '') {
      toast.error('용량이 선택되지 않았습니다.');
      return false;
    }
    if (tank.region === '') {
      toast.error('지역이 선택되지 않았습니다.');
      return false;
    }
    if (tank.serialNo.trim() === '') {
      toast.error('용기 일련번호가 입력되지 않았습니다.');
      return false;
    }
    if (tank.manufacturer === '') {
      toast.error('용기 제조사가 선택되지 않았습니다.');
      return false;
    }
    if (tank.velveSerialNo.trim() === '') {
      toast.error('밸브 일련번호가 입력되지 않았습니다.');
      return false;
    }
    if (tank.velveManufacturer === '') {
      toast.error('밸브 제조사가 선택되지 않았습니다.');
      return false;
    }
    if (tank.velveVersion === '') {
      toast.error('밸브 버전이 선택되지 않았습니다.');
      return false;
    }

    return true;
  };

  const doSave = () => {
    if (!validation()) {
      return;
    }

    // 임시 등록인 경우
    if (isDraft) {
      API.post('/tanks/empty', { qrcode: tank.qrcode }).then(({ success }) => {
        if (success) {
          navigate('/tanks');
        } else {
          toast.error('저장을 실패했습니다.');
        }
      });
      return;
    }

    const data = {
      qrcode: tank.qrcode,
      useStatus: tank.useStatus,
      chargeStatus: tank.chargeStatus,
      locationRid: tank.locationRid,
      type: tank.type,
      size: tank.size,
      region: tank.region,
      serialNo: tank.serialNo,
      manufacturer: tank.manufacturer,
      manufacturedDate: tank.manufacturedDate.format('YYYYMMDD'),
      expireDate: tank.expireDate.format('YYYYMMDD'),
      velveSerialNo: tank.velveSerialNo,
      velveManufacturer: tank.velveManufacturer,
      velveVersion: tank.velveVersion,
      velveExpireDate: tank.velveExpireDate.format('YYYYMMDD'),
      memo: tank.memo,
    };

    API.post('/tanks', data).then(({ success }) => {
      if (success) {
        navigate('/tanks');
      } else {
        toast.error('저장을 실패했습니다.');
      }
    });
  };

  const isDraft = useMemo(() => tank.useStatus === 'DRAFT', [tank]);
  const isInUse = useMemo(() => tank.useStatus === 'INUSE', [tank]);
  const expireYears = useMemo(() => (tank.region === 'EU/USA' ? 20 : 15), [tank.region]);
  const calendar = <Calendar locale={koKR} showDateInput={false} />;

  return (
    <div className={cx('container')}>
      <Controlbar>
        <div>
          <Breadcrumb />
        </div>
      </Controlbar>
      <ContentWrapper>
        <FormSectionTitle title="기본정보" />
        <FormRow title="QR코드">
          <TextInput width={120} value={tank.qrcode} readOnly />
        </FormRow>
        <FormRow title="가용상태" required>
          <Select width={80} name="useStatus" value={tank.useStatus} onChange={handleChangeUseStatus}>
            <option value="DRAFT">임시</option>
            <option value="READY">준비</option>
            <option value="INUSE">사용</option>
            <option value="INSPECTING">검사</option>
            <option value="RETIRED">폐기</option>
          </Select>
          {isDraft && <FormRowInfo>임시 등록 후 모바일을 통해 등록 완료</FormRowInfo>}
        </FormRow>
        <FormRow title="충전상태" required={!isDraft && !isInUse}>
          <Select
            width={80}
            name="chargeStatus"
            value={tank.chargeStatus}
            disabled={isDraft || isInUse}
            onChange={handleChange}>
            <option value="CHARGED">충전</option>
            <option value="UNCHARGED">미충전</option>
          </Select>
        </FormRow>
        <FormRow title="현재 위치" required={!isDraft && !isInUse}>
          <Select
            width={240}
            name="locationRid"
            value={tank.locationRid}
            disabled={isDraft || isInUse}
            onChange={handleChange}>
            <option value="" disabled>
              선택
            </option>
            {locations?.map((location, index) => (
              <option key={index} value={location.rid} disabled={location.name === '주문고객'}>
                {location.name}
              </option>
            ))}
          </Select>
        </FormRow>
        <FormRow title="유형" required={!isDraft}>
          <Select width={80} name="type" value={tank.type} disabled={isDraft} onChange={handleChange}>
            <option value="">선택</option>
            <option value="TYPE1">TYPE1</option>
            <option value="TYPE2">TYPE2</option>
            <option value="TYPE3">TYPE3</option>
            <option value="TYPE4">TYPE4</option>
          </Select>
        </FormRow>
        <FormRow title="용량" required={!isDraft}>
          <Select width={80} name="size" value={tank.size} disabled={isDraft} onChange={handleChange}>
            <option value="">선택</option>
            <option value={7}>7L</option>
            <option value={9}>9L</option>
            <option value={10.8}>10.8L</option>
            <option value={12}>12L</option>
          </Select>
        </FormRow>
        <FormRow title="지역" required={!isDraft}>
          <Select width={120} name="region" value={tank.region} disabled={isDraft} onChange={handleChange}>
            <option value="">선택</option>
            <option value="KOREA">KOREA</option>
            <option value="EU/USA">EU/USA</option>
          </Select>
        </FormRow>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ flex: 1 }}>
            <FormRow title="용기 일련번호" required={!isDraft}>
              <TextInput
                name="serialNo"
                width={240}
                value={tank.serialNo}
                placeholder="일련번호"
                disabled={isDraft}
                onChange={handleChange}
              />
            </FormRow>
            <FormRow title="용기 제조사" required={!isDraft}>
              <Select
                width={80}
                name="manufacturer"
                value={tank.manufacturer}
                disabled={isDraft}
                onChange={handleChange}>
                <option value="">선택</option>
                <option value="태광">태광</option>
                <option value="일진">일진</option>
                <option value="시노마">시노마</option>
              </Select>
            </FormRow>
            <FormRow title="제조일자" required={!isDraft}>
              <Picker onChange={(value) => handleChangeDate('manufacturedDate', value)} calendar={calendar}>
                {({ value }) => (
                  <TextInput
                    width={120}
                    value={tank.manufacturedDate ? tank.manufacturedDate.format('YYYY-MM-DD') : ''}
                    placeholder="제조일자"
                    disabled={isDraft}
                    readOnly
                  />
                )}
              </Picker>
            </FormRow>
            <FormRow title="용기 충전기한" required={!isDraft}>
              <Picker onChange={(value) => handleChangeDate('expireDate', value)} calendar={calendar}>
                {({ value }) => (
                  <TextInput
                    width={120}
                    value={tank.expireDate ? tank.expireDate.format('YYYY-MM-DD') : ''}
                    placeholder="용기 충전기한"
                    disabled={isDraft}
                    readOnly
                  />
                )}
              </Picker>
              <FormRowInfo>기본값 : 제조일자로부터 {expireYears}년</FormRowInfo>
            </FormRow>
          </div>
          <div style={{ flex: 1 }}>
            <FormRow title="밸브 일련번호" required={!isDraft}>
              <TextInput
                name="velveSerialNo"
                width={240}
                value={tank.velveSerialNo}
                placeholder="밸브 일련번호"
                disabled={isDraft}
                onChange={handleChange}
              />
            </FormRow>
            <FormRow title="밸브 제조사" required={!isDraft}>
              <Select
                width={80}
                name="velveManufacturer"
                value={tank.velveManufacturer}
                disabled={isDraft}
                onChange={handleChange}>
                <option value="">선택</option>
                <option value="태광">태광</option>
                <option value="영도">영도</option>
              </Select>
            </FormRow>
            <FormRow title="밸브 버전" required={!isDraft}>
              <Select
                width={80}
                name="velveVersion"
                value={tank.velveVersion}
                disabled={isDraft}
                onChange={handleChange}>
                <option value="">선택</option>
                <option value="V01">V01</option>
                <option value="V02">V02</option>
                <option value="V03">V03</option>
                <option value="V03-1">V03-1</option>
                <option value="V04">V04</option>
                <option value="V05">V05</option>
                <option value="V06">V06</option>
                <option value="V07">V07</option>
                <option value="V08">V08</option>
                <option value="V09">V09</option>
                <option value="V10">V10</option>
              </Select>
            </FormRow>
            <FormRow title="밸브 충전기한" required={!isDraft}>
              <Picker onChange={(value) => handleChangeDate('velveExpireDate', value)} calendar={calendar}>
                {({ value }) => (
                  <TextInput
                    width={120}
                    value={tank.velveExpireDate ? tank.velveExpireDate.format('YYYY-MM-DD') : ''}
                    placeholder="밸브 충전기한"
                    disabled={isDraft}
                    readOnly
                  />
                )}
              </Picker>
              <FormRowInfo>기본값 : 제조일자로부터 5년</FormRowInfo>
            </FormRow>
          </div>
        </div>
        <FormSectionTitle title="부가정보" />
        <FormRow title="메모">
          <Textarea name="memo" value={tank.memo} placeholder="메모" height={120} onChange={handleChange} />
        </FormRow>
      </ContentWrapper>
      <Controlbar>
        <Button title="뒤로" onClick={moveToBack} />
        <Button title="저장" onClick={doSave} />
      </Controlbar>
    </div>
  );
};

export default Page;
