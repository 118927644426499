import React, { useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import moment from 'moment';

import styles from '../../../styles/card.module.scss';
import Table from '../../../components/ui/Table';
import TankSizeSelect from './TankSizeSelect';

const cx = classNames.bind(styles);

const ByDayCount = ({ data: tanks }) => {
  const [tankSize, setTankSize] = useState();
  const [enteredDayCount, setEnteredDayCount] = useState(25);

  const handleChangeTankSize = (e) => {
    setTankSize(Number(e.target.value));
  };

  const handleChangeDayCount = (e) => {
    const enteredValue = Number(e.target.value);

    if (!isNaN(enteredValue)) {
      setEnteredDayCount(enteredValue);
    }
  };

  const columns = [
    {
      key: 'customer',
      name: '주문고객명',
      exp: (item) => <div className={cx('ellipsis')}>{item.name}</div>,
      style: { flex: 1 },
    },
    {
      key: 'dayCount',
      name: '보유일수',
      exp: (item) => item.dayCount,
      width: 80,
      align: 'center',
    },
  ];

  const data = useMemo(() => {
    if (tanks === undefined) return;

    // 선택된 사이즈의 용기
    const filteredTanks = tanks.filter((tank) => (tankSize ? tankSize === tank.size : true));

    // 주문고객별 최대 보유일수 객체화
    const dayCountOfCustomer = filteredTanks.reduce((acc, tank) => {
      if (tank.deliveryInfo === undefined) return acc;

      const dayCount = moment().diff(moment(tank.deliveryInfo.deliveredAt), 'day') + 1;
      const customerName = tank.deliveryInfo.deliveryCompany;

      // 입력된 보유일수 이상인 건 대상
      if (dayCount >= enteredDayCount) {
        if (acc[customerName]) {
          acc[customerName] = Math.max(acc[customerName], dayCount);
        } else {
          acc[customerName] = dayCount;
        }
      }

      return acc;
    }, {});

    // 객체 배열화
    const mappedData = Object.entries(dayCountOfCustomer).map(([name, dayCount]) => ({ name, dayCount }));

    // 주문고객명 오름차순 정렬
    mappedData.sort((a, b) => (a.name < b.name ? -1 : 1));

    return mappedData;
  }, [tanks, tankSize, enteredDayCount]);

  return (
    <div className={cx('container')}>
      <div className={cx('header')}>
        <div className={cx('title')}>고객별 보유일수</div>
        <div className={cx('options')}>
          <TankSizeSelect value={tankSize} onChange={handleChangeTankSize} className={cx('select')} />
          <div className={cx('fieldWrapper')}>
            <input value={enteredDayCount} onChange={handleChangeDayCount} maxLength={3} className={cx('field')} />
            <span className={cx('post')}>일 이상</span>
          </div>
        </div>
      </div>
      <div className={cx('body')}>
        <Table columns={columns} data={data} />
      </div>
    </div>
  );
};

export default ByDayCount;
