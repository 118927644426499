import React from 'react';
import classNames from 'classnames/bind';

import styles from './Select.module.scss';

const cx = classNames.bind(styles);

const Select = (props) => {
  const style = props.style ?? {};
  if (props.width) {
    style.width = props.width;
  }

  return (
    <select {...props} className={cx(['container', props.className])} style={style}>
      {props.children}
    </select>
  );
};

export default Select;
