import React from 'react';
import classNames from 'classnames/bind';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

const Page = () => {
  return (
    <div className={cx('container')}>
      <div className={cx('wrapper')}>
        <div className={cx('text')}>
          <div className={cx('welcome')}>
            Welcome to
            <br />
            <div className={cx('system')}>
              두산모빌리티이노베이션
              <br />
              H2LOGIS BACK OFFICE
            </div>
          </div>
          <div className={cx('copyright')}>© 2023 두산모빌리티이노베이션 ALL RIGHTS RESERVED.</div>
        </div>
        <div className={cx('image')} />
      </div>
    </div>
  );
};

export default Page;
