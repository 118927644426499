import React from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';

import styles from './Detail.module.scss';
import Table from '../../../components/ui/Table';
import { getOrderStatus } from '../../../utils/CodeMapper';

const cx = classNames.bind(styles);

const Detail = ({ data: orders }) => {
  const navigate = useNavigate();

  const columns = [
    {
      key: 'originNo',
      name: '주문번호',
      width: 120,
      align: 'center',
      exp: (item) => item.originNo,
      onPress: (item) => navigate(`/orders/${item.rid}`),
    },
    {
      key: 'status',
      name: '상태',
      width: 80,
      align: 'center',
      exp: (item) => getOrderStatus(item.status),
      onPress: (item) => navigate(`/orders/${item.rid}`),
    },
    {
      key: 'quantity',
      name: '수량',
      width: 80,
      align: 'center',
      exp: (item) => item.quantity,
      onPress: (item) => navigate(`/orders/${item.rid}`),
    },
    {
      key: 'customerName',
      name: '주문자명',
      exp: (item) => <div className={cx('ellipsis')}>{item.customerName}</div>,
      width: 120,
      onPress: (item) => navigate(`/orders/${item.rid}`),
    },
    {
      key: 'deliveryName',
      name: '수신자명',
      exp: (item) => <div className={cx('ellipsis')}>{item.deliveryName}</div>,
      width: 120,
      onPress: (item) => navigate(`/orders/${item.rid}`),
    },
    {
      key: 'address',
      name: '주소',
      exp: (item) => (
        <div className={cx('ellipsis')}>
          {item.deliveryAddress} {item.deliveryAddressDetail}
        </div>
      ),
      style: { flex: 1 },
      onPress: (item) => navigate(`/orders/${item.rid}`),
    },
  ];

  return (
    <div className={cx('container')}>
      <Table absoluteFill={false} data={orders} columns={columns} />
    </div>
  );
};

export default Detail;
