import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import Picker from 'rc-calendar/lib/Picker';
import Calendar from 'rc-calendar/lib/Calendar';
import koKR from 'rc-calendar/lib/locale/ko_KR';
import 'rc-calendar/assets/index.css';

import styles from '../../styles/form.module.scss';
import API from '../../helpers/API';
import TextInput from '../../components/ui/TextInput';
import Textarea from '../../components/ui/Textarea';
import Button from '../../components/ui/Button';
import Controlbar from '../../components/ui/Controlbar';
import ContentWrapper from '../../components/ui/ContentWrapper';
import FormSectionTitle from '../../components/ui/FormSectionTitle';
import FormRow from '../../components/ui/FormRow';
import Breadcrumb from '../../components/ui/Breadcrumb';
import { getAddress } from '../../utils/AddressFinder';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const [order, setOrder] = useState({
    originNo: '',
    orderDate: moment(),
    quantity: '',
    customerName: '',
    customerCompany: '',
    customerPhone: '',
    deliveryName: '',
    deliveryCompany: '',
    deliveryPhone: '',
    deliveryAddress: '',
    deliveryAddressDetail: '',
    memo: '',
  });

  const handleChange = (e) => {
    setOrder({
      ...order,
      [e.target.name]: e.target.value,
    });
  };

  const findAddress = () => {
    getAddress().then((data) => setOrder({ ...order, deliveryAddress: data.address }));
  };

  const handleChangeDate = (key, value) => {
    setOrder({
      ...order,
      [key]: value,
    });
  };

  const moveToBack = () => {
    navigate(-1);
  };

  const validation = () => {
    if (order.originNo.trim() === '') {
      toast.error('주문번호가 입력되지 않았습니다.');
      return false;
    }
    if (order.quantity === '') {
      toast.error('수량이 입력되지 않았습니다.');
      return false;
    }
    if (order.customerName.trim() === '') {
      toast.error('주문자명이 입력되지 않았습니다.');
      return false;
    }
    if (order.customerCompany.trim() === '') {
      toast.error('주문자 회사명이 입력되지 않았습니다.');
      return false;
    }
    if (order.customerPhone.trim() === '') {
      toast.error('주문자 전화번호가 입력되지 않았습니다.');
      return false;
    }
    if (order.deliveryName.trim() === '') {
      toast.error('수신자명이 입력되지 않았습니다.');
      return false;
    }
    if (order.deliveryCompany.trim() === '') {
      toast.error('수신자 회사명이 입력되지 않았습니다.');
      return false;
    }
    if (order.deliveryPhone.trim() === '') {
      toast.error('수신자 전화번호가 입력되지 않았습니다.');
      return false;
    }
    if (order.deliveryAddress.trim() === '') {
      toast.error('주소가 입력되지 않았습니다.');
      return false;
    }

    return true;
  };

  const doSave = () => {
    if (!validation()) {
      return;
    }

    const data = {
      ...order,
      orderDate: order.orderDate.format('YYYYMMDD'),
      status: 'RECEIVED',
      quantity: Number(order.quantity),
    };

    API.post('/orders', data).then(({ success }) => {
      if (success) {
        navigate('/orders');
      } else {
        toast.error('저장을 실패했습니다.');
      }
    });
  };

  const calendar = <Calendar locale={koKR} showDateInput={false} />;

  return (
    <div className={cx('container')}>
      <Controlbar>
        <div>
          <Breadcrumb />
        </div>
      </Controlbar>
      <ContentWrapper>
        <FormSectionTitle title="주문정보" />
        <FormRow title="주문번호" required>
          <TextInput
            name="originNo"
            width={240}
            value={order.originNo}
            placeholder="주문번호"
            onChange={handleChange}
          />
        </FormRow>
        <FormRow title="주문일자" required>
          <Picker onChange={(value) => handleChangeDate('orderDate', value)} calendar={calendar}>
            {({ value }) => (
              <TextInput width={120} value={order.orderDate.format('YYYY-MM-DD')} placeholder="주문일자" readOnly />
            )}
          </Picker>
        </FormRow>
        <FormRow title="수량" required>
          <TextInput name="quantity" width={80} value={order.quantity} placeholder="수량" onChange={handleChange} />
        </FormRow>
        <FormRow title="주문자명" required>
          <TextInput
            name="customerName"
            width={120}
            value={order.customerName}
            placeholder="주문자명"
            onChange={handleChange}
          />
        </FormRow>
        <FormRow title="주문자 회사명" required>
          <TextInput
            name="customerCompany"
            width={320}
            value={order.customerCompany}
            placeholder="주문자 회사명"
            onChange={handleChange}
          />
        </FormRow>
        <FormRow title="주문자 전화번호" required>
          <TextInput
            name="customerPhone"
            width={240}
            value={order.customerPhone}
            placeholder="주문자 전화번호"
            onChange={handleChange}
          />
        </FormRow>
        <FormSectionTitle title="배송정보" />
        <FormRow title="수신자명" required>
          <TextInput
            name="deliveryName"
            width={120}
            value={order.deliveryName}
            placeholder="수신자명"
            onChange={handleChange}
          />
        </FormRow>
        <FormRow title="수신자 회사명" required>
          <TextInput
            name="deliveryCompany"
            width={320}
            value={order.deliveryCompany}
            placeholder="수신자 회사명"
            onChange={handleChange}
          />
        </FormRow>
        <FormRow title="수신자 전화번호" required>
          <TextInput
            name="deliveryPhone"
            width={240}
            value={order.deliveryPhone}
            placeholder="수신자 전화번호"
            onChange={handleChange}
            className={cx('w240')}
          />
        </FormRow>
        <FormRow title="주소" required>
          <TextInput
            name="deliveryAddress"
            width={400}
            value={order.deliveryAddress}
            placeholder="주소"
            className={cx('w400')}
            readOnly
            disabled
          />
          <TextInput
            name="deliveryAddressDetail"
            width={320}
            value={order.deliveryAddressDetail}
            placeholder="상세주소 (선택)"
            onChange={handleChange}
            style={{ marginLeft: 8 }}
          />
          <Button variant="bordered" title="주소 찾기" onClick={findAddress} style={{ marginLeft: 8 }} />
        </FormRow>
        <FormSectionTitle title="부가정보" />
        <FormRow title="메모">
          <Textarea name="memo" value={order.memo} placeholder="메모" height={120} onChange={handleChange} />
        </FormRow>
      </ContentWrapper>
      <Controlbar>
        <Button title="뒤로" onClick={moveToBack} />
        <Button title="저장" onClick={doSave} />
      </Controlbar>
    </div>
  );
};

export default Page;
