import React, { useState } from 'react';
import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import Picker from 'rc-calendar/lib/Picker';
import Calendar from 'rc-calendar/lib/Calendar';
import koKR from 'rc-calendar/lib/locale/ko_KR';
import 'rc-calendar/assets/index.css';

import styles from '../../styles/form.module.scss';
import API from '../../helpers/API';
import TextInput from '../../components/ui/TextInput';
import Textarea from '../../components/ui/Textarea';
import Button from '../../components/ui/Button';
import Controlbar from '../../components/ui/Controlbar';
import ContentWrapper from '../../components/ui/ContentWrapper';
import FormSectionTitle from '../../components/ui/FormSectionTitle';
import FormRow from '../../components/ui/FormRow';
import { getOrderStatus } from '../../utils/CodeMapper';
import { getAddress } from '../../utils/AddressFinder';
import Breadcrumb from '../../components/ui/Breadcrumb';

const cx = classNames.bind(styles);

const Page = () => {
  const { rid } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState();

  useMountEffect(() => {
    // API: 주문 상세 조회
    API.get(`/orders/${rid}`).then(({ success, data }) => {
      if (success) {
        setOrder({
          ...data,
          orderDate: moment(data.orderDate, 'YYYYMMDD'),
        });
      }
    });
  });

  const handleChange = (e) => {
    setOrder({
      ...order,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeDate = (key, value) => {
    setOrder({
      ...order,
      [key]: value,
    });
  };

  const findAddress = () => {
    getAddress().then((data) => setOrder({ ...order, deliveryAddress: data.address }));
  };

  const moveToBack = () => {
    navigate(-1);
  };

  const moveToOrderTanks = () => {
    navigate(`/orders/${rid}/tanks`);
  };

  const moveToOrderShipping = () => {
    if (order.orderTanks.length === 0) {
      toast.error('할당된 용기가 없습니다.');
      return;
    }
    navigate(`/orders/${rid}/shipping`);
  };

  const validation = () => {
    if (order.originNo.trim() === '') {
      toast.error('주문번호가 입력되지 않았습니다.');
      return false;
    }
    if (order.quantity === '') {
      toast.error('수량이 입력되지 않았습니다.');
      return false;
    }
    if (order.customerName.trim() === '') {
      toast.error('주문자명이 입력되지 않았습니다.');
      return false;
    }
    if (order.customerCompany.trim() === '') {
      toast.error('주문자 회사명이 입력되지 않았습니다.');
      return false;
    }
    if (order.customerPhone.trim() === '') {
      toast.error('주문자 전화번호가 입력되지 않았습니다.');
      return false;
    }
    if (order.deliveryName.trim() === '') {
      toast.error('수신자명이 입력되지 않았습니다.');
      return false;
    }
    if (order.deliveryCompany.trim() === '') {
      toast.error('수신자 회사명이 입력되지 않았습니다.');
      return false;
    }
    if (order.deliveryPhone.trim() === '') {
      toast.error('수신자 전화번호가 입력되지 않았습니다.');
      return false;
    }
    if (order.deliveryAddress.trim() === '') {
      toast.error('주소가 입력되지 않았습니다.');
      return false;
    }

    return true;
  };

  const doSave = () => {
    if (!validation()) {
      return;
    }

    const data = {
      originNo: order.originNo,
      orderDate: order.orderDate.format('YYYYMMDD'),
      status: order.status,
      quantity: Number(order.quantity),
      customerName: order.customerName,
      customerCompany: order.customerCompany,
      customerPhone: order.customerPhone,
      deliveryName: order.deliveryName,
      deliveryCompany: order.deliveryCompany,
      deliveryPhone: order.deliveryPhone,
      deliveryAddress: order.deliveryAddress,
      deliveryAddressDetail: order.deliveryAddressDetail,
      memo: order.memo,
    };

    // API: 주문 수정
    API.patch(`/orders/${rid}`, data).then(({ success }) => {
      if (success) {
        navigate('/orders');
      } else {
        toast.error('저장을 실패했습니다.');
      }
    });
  };

  const calendar = <Calendar locale={koKR} showDateInput={false} />;

  return (
    <div className={cx('container')}>
      <Controlbar>
        <div>
          <Breadcrumb />
        </div>
      </Controlbar>
      <ContentWrapper>
        {order && (
          <div>
            <FormSectionTitle title="주문정보" />
            <FormRow title="주문번호" required>
              <TextInput
                name="originNo"
                width={240}
                value={order.originNo}
                placeholder="주문번호"
                onChange={handleChange}
              />
            </FormRow>
            <FormRow title="주문일자" required>
              <Picker onChange={(value) => handleChangeDate('orderDate', value)} calendar={calendar}>
                {({ value }) => (
                  <TextInput width={120} value={order.orderDate.format('YYYY-MM-DD')} placeholder="주문일자" readOnly />
                )}
              </Picker>
            </FormRow>
            <FormRow title="상태">
              <div className={cx('value')}>{getOrderStatus(order.status)}</div>
            </FormRow>
            <FormRow title="수량" required>
              <TextInput name="quantity" width={80} value={order.quantity} placeholder="수량" onChange={handleChange} />
            </FormRow>
            <FormRow title="주문자명" required>
              <TextInput
                name="customerName"
                width={120}
                value={order.customerName}
                placeholder="주문자명"
                onChange={handleChange}
              />
            </FormRow>
            <FormRow title="주문자 회사명" required>
              <TextInput
                name="customerCompany"
                width={320}
                value={order.customerCompany}
                placeholder="주문자 회사명"
                onChange={handleChange}
              />
            </FormRow>
            <FormRow title="주문자 전화번호" required>
              <TextInput
                name="customerPhone"
                width={240}
                value={order.customerPhone}
                placeholder="주문자 전화번호"
                onChange={handleChange}
              />
            </FormRow>
            <FormSectionTitle title="배송정보" />
            <FormRow title="수신자명" required>
              <TextInput
                name="deliveryName"
                width={120}
                value={order.deliveryName}
                placeholder="수신자명"
                onChange={handleChange}
              />
            </FormRow>
            <FormRow title="수신자 회사명" required>
              <TextInput
                name="deliveryCompany"
                width={320}
                value={order.deliveryCompany}
                placeholder="수신자 회사명"
                onChange={handleChange}
              />
            </FormRow>
            <FormRow title="수신자 전화번호" required>
              <TextInput
                name="deliveryPhone"
                width={240}
                value={order.deliveryPhone}
                placeholder="수신자 전화번호"
                onChange={handleChange}
              />
            </FormRow>
            <FormRow title="주소" required>
              <TextInput
                name="deliveryAddress"
                width={400}
                value={order.deliveryAddress}
                placeholder="주소"
                readOnly
                disabled
              />
              <TextInput
                name="deliveryAddressDetail"
                width={320}
                value={order.deliveryAddressDetail}
                placeholder="상세주소 (선택)"
                onChange={handleChange}
                style={{ marginLeft: 8 }}
              />
              <Button variant="bordered" onClick={findAddress} title="주소 찾기" style={{ marginLeft: 8 }} />
            </FormRow>
            <FormSectionTitle title="부가정보" />
            <FormRow title="메모">
              <Textarea name="memo" value={order.memo} placeholder="메모" height={120} onChange={handleChange} />
            </FormRow>
            <FormRow title="등록일시">
              <div className={cx('value')}>{moment(order.createdAt).format('YYYY-MM-DD HH:mm:ss')}</div>
            </FormRow>
            <FormRow title="등록자">
              <div className={cx('value')}>{order.createdBy.name}</div>
            </FormRow>
            <FormRow title="수정일시">
              <div className={cx('value')}>{moment(order.updatedAt).format('YYYY-MM-DD HH:mm:ss')}</div>
            </FormRow>
            <FormRow title="수정자">
              <div className={cx('value')}>{order.updatedBy.name}</div>
            </FormRow>
          </div>
        )}
      </ContentWrapper>
      <Controlbar>
        <Button title="뒤로" onClick={moveToBack} />
        <div>
          <Button title="용기 관리" onClick={moveToOrderTanks} />
          <Button title="배송 관리" onClick={moveToOrderShipping} />
          <Button title="저장" onClick={doSave} />
        </div>
      </Controlbar>
    </div>
  );
};

export default Page;
